.AudioPlaylist {
	width: 100%;
    margin: 30px 0;
    font-family: 'Roboto', sans-serif;
}

.AudioPlaylistHiddenPlayer {
	display: none;
}

.AudioPlaylistInfoTitle {
	text-align: center;	
	mix-blend-mode: difference;
	color: white;
	z-index: 2;
}

.AudioPlaylistControls {
	height: 130px;	
}

.AudioPlaylistButton {
	display: inline-block;
	vertical-align: middle;
	margin-right: 2px;
	border: 1px solid #333;
	width: 50px;
	text-align: center;
	cursor: pointer;
	line-height: 48px;
	height: 50px;
	margin-bottom: 10px;
	font-size: 11pt;
}

.AudioPlaylistProgressBarContainer {
    width: 90%;
    margin: 0 5% 10px 5%;
	height: 30px;
	line-height: 30px;
	background-color: #111;
	margin-right: 2px;
	border: 1px solid #333;
	cursor: pointer;
	overflow: hidden;
	font-size: 7pt;
}

.AudioPlaylistProgressBar {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 0;
	background-color: #333;	
}

.AudioPlaylistTime {
    margin-left: 5%;
	float: left;
	height: 20px;
	line-height: 18px;
	width: 60px;
	text-align: center;
	background-color: #111;
	margin-right: 2px;
	border: 1px solid #333;
	pointer-events: none;
    font-size: 8pt;
    color: #eee;
}

.AudioPlaylistVolumeBarContainer {
    float: right;
    margin-right: 5%;
	vertical-align: middle;
	width: 75px;
	height: 20px;
	background-color: #111;
	
	border: 1px solid #333;
	cursor: pointer;
}

.AudioPlaylistVolumeBarContainer .fa {
	mix-blend-mode: difference;
	color: white;
    padding: 0 5px;
    font-size: 10pt;
}

.AudioPlaylistVolumeBar {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 0;
	background-color: #333;
}

.AudioPlaylistItems {
    margin-top: 5px;
    width: 90%;
    margin: 0 auto;
	border-top: 1px solid #555;
}

.AudioPlaylistItem {
	cursor: pointer;
	border-bottom: 1px solid #555;
	padding: 5px;
	transition: all 0.3s;
	font-size: 9pt;
	letter-spacing: 0.5pt;
	height: 30px;
	line-height: 23px;
	width: 100%;
	overflow: hidden;
}

.Unplayable {
	cursor: default;
	opacity: 0.5;
}

.AudioPlaylistItem .fa {
	float: left;
	color: #eee;
	height: 30px;
    line-height: 23px;
    padding-right: 10px;
}

.Unplayable .fa {
	color: transparent;
}

.AudioPlaylistItem:hover {
	background-color: #222;
}

.Unplayable:hover {
	background-color: transparent;
}

.AudioPlaylistItemTitle {
	margin-left: 3px;
	float: left;
	width: 85%;
	text-align: left;
}

.AudioPlaylistItemLength {
	float: right;
	text-align: right;
}

.AudioPlaylistItemPlaying {
	background-color: #222;
}