.MinipageOuter  {
    position: relative;
}

.MinipageBackground {
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    position: absolute;
    width: 100%;
	height: 100%;
}

.MinipageInner {
    min-width: 320px;
    max-width: 500px;
    min-height: 100vh;
    margin: 0 auto;
    font-family: 'Lato', sans-serif;
    text-align: center;
    background-color: #111111;
    color: #eee;
    box-shadow: 0 -3px 5px 2px rgba(0, 0, 0, 0.3);
    padding-bottom: 20px;
    filter: blur(0);
}

.MinipageCover {
    width: 100%;
    height: auto;
    padding: 0;
}

.MinipageCover img {
    width: 100%;
    height: auto;
    margin: 0;
}

.MinipageTitle {
    top: -4px;
    padding: 20px 0;   
    border-bottom: 1px solid #333;
    background-color: darkorange;
}

.MinipageTitleTitle {
    font-size: 20pt;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}

.MinipageTitleArtists {
    font-size: 16pt;
    font-family: 'Lato', sans-serif;
}

.MinipageLink {
    font-size: 9pt;
    min-height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #333;
    transition: background-color 0.3s;
    cursor: pointer;
}

.MinipageLink:hover {
    background-color: #222;
}

.MinipageLinkLeft {
    display: inline-block;
    width: 65%;
    text-align: left;
    padding-left: 20px;
    vertical-align: middle;
    font-size: 10pt;
    font-family: 'Lato', sans-serif;  
}

.MinipageLinkLeft img {
    top: 7px;
    height: 30px;
}

.MinipageLinkLeft .fab, .MinipageLinkLeft .fa {    
    padding-right: 15px;
}

.MinipageLinkRight {
    display: inline-block;
    width: 35%;
    text-align: right;
    padding-right: 20px;
    vertical-align: middle;
}

.MinipageDescription {
    font-family: 'Lato', sans-serif;
    font-size: 10pt;
    text-align: justify;
    width: 90%;
    margin: 0 auto;
    padding: 10px 0;
}

.MinipageTags {
    font-family: 'Lato', sans-serif;
    font-size: 10pt;
    color: #999;
    width: 90%;
    margin: 20px auto 0 auto;
}

.MinipageReleaseDate {
    font-family: 'Lato', sans-serif;
    font-size: 10pt;
    width: 90%;
    margin: 20px auto 0 auto;
}

.MinipageCatalogNumber {
    font-size: 10pt;
    color: #999;
    margin: 20px 0;
}

.MinipageEG {
    font-family: 'Lato', sans-serif;
    font-size: 9pt;
    color: #eee;
    margin: 20px 0 0 0;
}

.MinipageEG a {
    color: #eee;
}

.MinipageEG a:hover {
    color: #999;
}