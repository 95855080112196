.CatalogWrapper {
    background-color: white;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;
}

.Catalog {
    min-width: 320px;
    max-width: 500px;
    min-height: 100vh;
    margin: 0 auto;
    font-family: 'Lato', sans-serif;
    text-align: center;
    background-color: #111111;
    color: #eee;
    box-shadow: 0 -3px 5px 2px rgba(0, 0, 0, 0.3);
    padding-bottom: 20px;
    filter: blur(0);
    opacity: 0.95;
}

.CatalogBackground {
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.CatalogUserInfo {
    width: 100%;
}

.CatalogUserLogo {
    position: relative;
    width: 100%;
    padding: 20px;
    margin: 0;
}

.CatalogUserLogo img {
    width: 100%;
    height: auto;
    padding: 0;
    margin-bottom: -5px;
}

.CatalogUserName {    
    font-size: 27pt;
    height: 60px;
    line-height: 60px;
    background-color: darkorange;
    margin: 0;
}


.CatalogUserSocials {
    margin: 15px 0;
    text-align: center;
    color: #eee !important;
}

.CatalogUserSocials a {
    font-size: 12pt;
    transition: all 0.3s;
    color: #eee;
}

.CatalogUserSocials a:hover {
    color: orange;
}

.CatalogUserSocial {
    display: inline-block;
    margin: 0 10px;
    font-size: 18pt;
    cursor: pointer;
    transition: all 0.3s;
}

.CatalogUserSocial:hover {
    color: orange;
}

.CatalogUserDescription {
    text-align: justify;
    padding: 0 30px;
    font-size: 10pt;
    line-height: 20px;
}

.CatalogReleases {
    width: 100%;
}

.CatalogReleasesTitle {
    font-size: 25pt;
    height: 60px;
    line-height: 60px;
    background-color: #222;
    border-bottom: 1px solid #888;
    border-top: 1px solid #888;
    margin-bottom: 10px;
}

.CatalogReleaseList {
    width: 100%;
}

.CatalogRelease {
    display: inline-block;
    width: 30%;
    margin: 1%;
    cursor: pointer;
    vertical-align: top;
}

.CatalogRelease:hover .CatalogReleaseTitle {
    background-color: #333;
}

.CatalogReleaseCover {
    width: 100%;
}

.CatalogReleaseCover img {
    width: 100%;
    height: auto;
}

.CatalogReleaseInfo {
    width: 100%;
}

.CatalogReleaseTitle {
    display: none;
}

.CatalogReleaseArtist {
    display: none;
}


.MinipageEG {
    font-family: 'Lato', sans-serif;
    font-size: 9pt;
    color: #eee;
    margin: 20px 0 0 0;
}

.MinipageEG a {
    color: #eee;
}

.MinipageEG a:hover {
    color: #999;
}