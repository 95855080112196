.BillingCurrentPlan {
	font-size: 14pt;
	padding: 20px 0;
}


.BillingUpgrade {
    margin: 20px 0;
    padding: 10px 20px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.3);
    
    cursor: pointer;
    background-color: orange;
    color: #eee;
    border-radius: 5px;
    opacity: 0.8;
    transition: opacity 0.3s;
}

.BillingUpgrade:hover {
    opacity: 1;
}

.BillingUpgradeLogo {
    font-size: 45pt;
    line-height: 90px;
    display: inline-block;
    width: 70px;
    vertical-align: middle;
}

.BillingUpgradeText {
    font-size: 18pt;
    display: inline-block; 
	padding-left: 30px;   
}

.BillingCancel {
    margin: 20px 0;
    padding: 10px 20px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.3);
    
    cursor: pointer;
    background-color: red;
    color: #eee;
    border-radius: 5px;
    opacity: 0.8;
    transition: opacity 0.3s;
}

.BillingCancel:hover {
    opacity: 1;
}

.BillingCancelLogo {
    font-size: 25pt;
    line-height: 50px;
    display: inline-block;
    width: 70px;
    vertical-align: middle;
}

.BillingCancelText {
    font-size: 12pt;
    display: inline-block; 
	padding-left: 30px;
    width: calc(100% - 70px);
    text-align: right;
}

.BillingPlan {
    display: inline;
    background: #333;
    color: #eee;
    padding: 10px;
    font-style: italic;
}

.BillingPlanDetails {
    box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
    padding: 20px;
    margin: 20px 0;
}

.BillingPlanDetailsLabel {
    display: inline-block;
    font-weight: bold;
    width: 150px;
    padding: 10px 0;
    border-bottom: 1px solid #888;
}

.BillingPlanDetailsStatus, .BillingPlanDetailsNextBilling {
    display: inline-block;
    width: 150px;
    text-align: right;
    padding: 10px 0;
    border-bottom: 1px solid #888;
}