.Stats {
    width: 940px;
	height: 100%;
	margin: 0 auto;
}

.Stats select {
	width: 100%;
	margin-left: 0;
}

.Stats .DashboardComponentSection {
	width: 100%;
}

.StatsUpgrade {
	text-align: left;
}

.StatsUpgrade p {
	font-size: 14pt;
	padding: 20px 0;
}


.StatsUpgradeAction {
    margin: 20px 0;
    padding: 10px 20px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.3);
    width: 330px;
    cursor: pointer;
    background-color: orange;
    color: #eee;
    border-radius: 5px;
    opacity: 0.8;
    transition: opacity 0.3s;
}

.StatsUpgradeAction:hover {
    opacity: 1;
}

.StatsUpgradeActionLogo {
    font-size: 45pt;
    display: inline-block;
    width: 70px;
    vertical-align: middle;
}

.StatsUpgradeActionText {
    font-size: 20pt;
    display: inline-block; 
	padding-left: 30px;   
}