* {
	margin: 0;
	padding: 0;
	position: relative;
	box-sizing: border-box;
	font-family: sans-serif;
}

*:focus {
	outline: none;
}

::-webkit-scrollbar {
    width: 4px;
    margin-right: 4px;
    padding-right: 3px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

::-webkit-scrollbar-thumb {
    background-color: #333;
}

::-webkit-scrollbar-track {
    background-color: #666;
}

html, body {
	width: 100%;
	height: 100%;	
}

body {
	overflow-y: scroll;
}

a {
	text-decoration: none;
	color: black;
}

select {
	padding: 10px;
	border-radius: 5px;
	width: 90%;
	margin-left: 5%;
	text-align: center;
	cursor: pointer;
	color: #888;
	font-weight: 400;
	font-size: 10pt;
	letter-spacing: 1px;
	transition: 0.3s all;
	box-shadow: 0px 0px 2px #9E5800;
	background-color: orange;
	color: white;
	text-shadow: 0px 0px 1px #9E5800;
}

.Form {
	width: 400px;
	margin: 0 auto;
}

.FormField {
	margin: 10px 0;
	font-family: 'Roboto', sans-serif;
}

.FormFieldLabel {
	text-align: left;
	width: 100%;
	margin: 3px 0;
	font-family: 'Roboto', sans-serif;
	font-size: 10pt;
}

.FormField p {
	font-family: 'Roboto', sans-serif;
	font-size: 8pt;
	padding: 5px 0;
}

.FormField .Error {
	font-family: 'Roboto', sans-serif;
	position: absolute;
	line-height: 50px;
	top: 0;
	left: 101%;
	color: red;
	font-size: 8pt;
	width: 200px;
	text-align: left;
}

input {
	font-family: 'Roboto', sans-serif;
	border: 1px solid #aaa;
	height: 50px;
	width: 100%;
	padding: 20px;	
	border-radius: 5px;
	box-shadow: 0px 0px 10px #eee;
}

.Button {
	padding: 10px;
	border-radius: 5px;
	width: 150px;
	text-align: center;
	cursor: pointer;
	color: #888;
	font-weight: 400;
	font-size: 10pt;
	letter-spacing: 1px;
	transition: 0.3s all;
	box-shadow: 0px 0px 2px #9E5800;
	background-color: orange;
	color: white;
	text-shadow: 0px 0px 1px #9E5800;
}

.ButtonContainer {
	width: 100%;
	text-align: center;
	display: block;
	margin: 5px 0;
}

.ButtonContainer .Button {
	margin: 0 auto;
}

.ButtonConfirmation {
	display: inline-block;
	text-transform: uppercase;
	margin-left: 5px;
	font-size: 9pt;
	vertical-align: middle;
	background-color: rgba(255, 255, 255, 0.7);
	padding: 0 20px;
}

.confirmation-enter {
	opacity: 0.01;
}
  
.confirmation-enter.confirmation-enter-active {
	opacity: 1;
	transition: opacity 300ms ease-in;
}
  
.confirmation-leave {
	opacity: 1;
}
  
.confirmation-leave.confirmation-leave-active {
	opacity: 0.01;
	transition: opacity 300ms ease-in;
}

.Inactive {
	background-color: #ccc;
	box-shadow: 0px 0px 2px #bbb;
	pointer-events: none;
}

.Button:hover {
	color: #eee;
}

.Button:active {
	color: #aaa;
}

.Message {
	color: red;
	font-size: 8pt;
	margin-top: 10px;
}

.Clear {
	clear: both;
}

#root {
	width: 100%;
	height: 100%;
}

.Wrapper {
	width: 100%;
	min-height: 100%;
	background-color: #fff;
}

.Tooltip {
	line-height: 20px;
	max-width: 300px;
	text-align: left;
	font-family: 'Roboto', sans-serif;
}

.fa-bandcamp {
	color: teal;
}

.fa-spotify {
	color: #84bd00;
}

.fa-amazon {
	color: #ff9900;
}

.fa-google {
	color: #4885ed;
}

.fa-soundcloud {
	color: #ff7700;
}

.fa-youtube {
	color: #ff0000;
}

.fa-facebook, .fa-facebook-f {
	color:#3b5998;
}

.fa-twitter {
	color: #00acee;
}

.fa-instagram {
	color: #C13584;
}

.CatalogUserSocial span[class^="fa"] {
	color: #eee;
	transition: color 0.3s;
}

.CatalogUserSocial span[class^="fa"]:hover {
	color: darkorange;
}