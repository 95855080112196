.Register {
	width: 700px;
	height: 700px;
	text-align: center;
}

.Cancel {
	width: 700px;
	height: 400px;
	text-align: center;
}

.Cancel .Button {
	margin: 20px auto;
}

.RegisterForm {
	width: 100%;
	font-family: 'Lato', sans-serif;
}

.RegisterFormTitle {
	font-family: 'Lato', sans-serif;
	font-size: 20pt;
	text-align: center;
	width: 100%;
	line-height: 100px;
}

.RegisterButton {
	margin-top: 40px;
	width: 100%;	
}

.RegisterButton .Button {
	margin: 0 auto;
	display: inline-block;
}

.RegisterButton .Error {
	color: red;
	font-size: 9pt;
	padding: 3px 0;
}

.FormFieldLabelBig {
	font-family: 'Lato', sans-serif;
	text-align: center;
	padding: 10px;
}

.FormFieldOption, .FormFieldOptionPremium {
	background-color: #fff;
	font-family: 'Lato', sans-serif;
	display: inline-block;
	width: 150px;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
	padding: 10px;
	margin: 0 10px;
	cursor: pointer;
	border: 2px solid rgba(0,0,0,0);
}

.FormFieldOptionPremium {}

.FormFieldOptionSelected {
	border: 2px solid orange;
}

.RegisterVerify {
	width: 400px;
	margin: 40px auto 0 auto;
}

.RegisterVerifyLogo {
    width: 400px;
    height: 402px;
    background-image: url("/overviewsquare.webp");
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto;
}

.RegisterVerifyText {
	font-family: 'Lato', sans-serif;
	text-align: justify;
	padding: 20px;
	line-height: 25px;
}

.RegisterVerifyPrice {
	font-size: 20pt;
	padding-bottom: 30px;
	margin-bottom: 20px;
	border-bottom: 1px dotted #333;
}
