.EditRelease {
	width: 940px;
	height: 100%;
	margin: 0 auto;
}

.EditReleaseCover {
	width: 450px;
	height: 450px;
	box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.3);
	background-color: #eee;
}

.EditReleaseCover input {
	display: none;
}

.EditReleaseCoverImage {
	width: 100%;
	height: 100%;
	line-height: 450px;
	text-align: center;
	color: #fff;
	font-size: 240pt;
	cursor: pointer;
}

.EditReleaseCoverImageText {
	position: absolute;
	top: 0;
	width: 100%;
	height: 30px;
	text-align: center;
	font-size: 10pt;
	color: #333;
	font-family: 'Lato', sans-serif;
}

.EditReleaseCoverImage img {
	width: 100%;
	height: 100%;
}

.EditReleaseCoverImageChange {
	font-family: 'Lato', sans-serif;
	position: absolute;
	bottom: 25px;
	left: -3px;
	width: calc(100% + 6px);
	line-height: 30px;
	text-align: center;		
	color: #333;
	font-size: 9pt;
	text-transform: uppercase;
	text-shadow: none;
	background-color: rgba(255,255,255,0.8);
	box-shadow: 0px 3px 2px 0px rgba(0,0,0,0.3);
	z-index: 3;
}

.CoverProgressBar {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	pointer-events: none;
	z-index: 2;
}

.CoverProgress {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.9);
	transition: width 0.1s;
}

.ReactTags__tags {
	height: 100%;
	line-height: 35px;
}

.ReactTags__tagInput {
	display: inline-block;
}

.ReactTags__tagInputField {
	width: 80px;
}
.ReactTags__selected {
	padding-left: 5px;
}

.ReactTags__selected .ReactTags__tag {
	font-family: 'Lato', sans-serif;
	background-color: orange;
	font-size: 10pt;
	height: 25px;
	line-height: 25px;
	color: #eee;
	padding: 0 0 0 10px;
	margin: 0 2px;
	border-radius: 3px;
	display: inline-block;
	vertical-align: middle;
}

.ReactTags__selected .ReactTags__remove {
	color: #fff;
	margin-left: 8px;
	height: 25px;
	line-height: 25px;
	font-size: 12pt;
	cursor: pointer;
	border-left: 1px dashed #eee;
	padding: 0 5px;
	display: inline-block;
	background-color: rgba(0,0,0,0.1);
	border-radius: 0 3px 3px 0;
}

.ReactTags__suggestions {
	position: absolute;
	z-index: 5;	
	background: #eee;
	height: 205px;
	overflow: hidden;
	cursor: pointer;
	padding: 0;
}

.ReactTags__suggestions li {
	padding: 3px 10px;
	
	transition: background-color 0.2s;
	margin: 0 3px;
	box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.1);
}

.ReactTags__activeSuggestion {
	background-color: #fafafa;
}

.EditReleaseMeta {
	width: 450px;
}

.EditReleaseMetaField {
	position: relative;
	line-height: 35px;
	margin: 0 0 5px 0;
	box-shadow: 2px 2px 2px #eee;
}

.EditReleaseMetaField:last-child {
	border: none;
}

.EditReleaseMetaDescription {
	height: 100px;
}

.EditReleaseMetaLabel {
	font-family: 'Lato', sans-serif;
	box-shadow: 1px 2px 3px 0px rgba(0,0,0,0.4);
	z-index: 1;
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 149px;
	height: 100%;
	font-size: 9pt;
	background-color: #666;
	color: #eee;
	padding: 0 2px 0 10px;
	letter-spacing: 1px;
	-webkit-user-select: none;  /* Chrome all / Safari all */
	-moz-user-select: none;     /* Firefox all */
	-ms-user-select: none;      /* IE 10+ */
	user-select: none;          /* Likely future */
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
}

.EditReleaseMetaLabelText {
	display: inline-block;
	width: calc(100% - 20px);
	vertical-align: middle;
}

.EditReleaseMetaLabelInfo {
	display: inline-block;
	width: 20px;
	cursor: help;
	font-size: 11pt;
	vertical-align: middle;
}

.EditReleaseMetaDescription .EditReleaseMetaLabel {
	width: 149px;
	height: 99px;
	line-height: 99px;
}

.EditReleaseMetaFormInput {
	position: relative;
	display: inline-block;
	width: calc(100% - 150px);
	height: 100%;
	font-size: 9pt;
}

.EditReleaseMetaFormInput input {
	height: 100%;
	width: 100%;
	padding: 10px;
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	border: 0;
}

.EditReleaseMetaFormInput textarea {
	font-family: 'Roboto', sans-serif;
	height: 99px;
	width: 100%;
	padding: 10px;
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	border: 0;
	resize: none;
}

.EditReleaseLinks {
	width: 920px;
}

.EditReleaseLinksList {
	width: 100%;
	margin-bottom: 20px;
}

.EditReleaseLinkHeaders {
	width: 100%;
	background-color: #666;
	box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.6);
	margin: 0 0 10px 0;
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
			 0px 2px 6px rgba(0,0,0,0.5);
	color: #eee;
	font-family: 'Lato', sans-serif;
	font-size: 9pt;
	height: 30px; 
	line-height: 30px;
}

.EditReleaseLinkHeader {
	display: inline-block;
	vertical-align: top;
	text-transform: uppercase;
	padding: 0 10px;
	text-align: center;
}

.EditReleaseLinkHandle {
	width: 35px;
	display: inline-block;
	cursor: move;
	user-select: none;
	color: #999;
	text-align: center;
	line-height: 35px;
}

.EditReleaseLinkHeaderPlatform {
	width: 164px;
}

.EditReleaseLinkHeaderURL {
	width: calc(100% - 364px);
}

.EditReleaseLinkHeaderAction {
	width: 100px;
}

.EditReleaseLinkHeaderTest {
	width: 100px;
}

.EditReleaseLinksList {
	width: 100%;
}

.EditReleaseLink {	
	height: 35px;
	line-height: 35px;
	margin: 0 0 5px 0;
	box-shadow: 2px 2px 2px #eee;
}

.LinkRemove {
	position: absolute;
	top: 0;
	right: -45px;
	width: 35px;
	line-height: 35px;
	height: 35px;
	text-align: center;
	background-color: #fafafa;
	font-size: 12pt;
	color: red;
	box-shadow: 2px 2px 3px rgba(0,0,0,0.05);
	cursor: pointer;
	transition: background-color 0.2s;
}

.LinkRemove:hover {
	background-color: #eee;
}

.EditReleaseLinkPlatform {
	font-family: 'Lato', sans-serif;
	box-shadow: 1px 2px 3px 0px rgba(0,0,0,0.4);
	z-index: 1;
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 164px;
	height: 100%;
	font-size: 10pt;
	background-color: #666;
	color: #eee;
	padding: 0;
	letter-spacing: 1px;
	-webkit-user-select: none;  /* Chrome all / Safari all */
	-moz-user-select: none;     /* Firefox all */
	-ms-user-select: none;      /* IE 10+ */
	user-select: none;          /* Likely future */
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
}

.EditReleaseLinkPlatformIcon {
	display: inline-block;
	vertical-align: middle;
	width: 40px;
	text-align: center;
}

.EditReleaseLinkPlatformIcon img {
	max-width: 20px;
	max-height: 100%;
	width: auto;
	height: auto;
}

.EditReleaseLinkPlatformLabel {
	display: inline-block;
	vertical-align: top;
	width: calc(100% - 40px);
	height: 100%;
}

.EditReleaseLinkPlatform .fab, .EditReleaseLinkPlatform .fa {
	font-size: 12pt;
	padding-right: 5px;
	line-height: 35px;
	vertical-align: bottom;
	width: 30px;
}

.EditReleaseLinkUrl {
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: calc(100% - 399px);
	height: 100%;
	font-size: 9pt;
}

.EditReleaseLinkAction {
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 100px;
	height: 100%;
	font-size: 9pt;
}

.EditReleaseLinkTest {
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 100px;
	height: 100%;
	font-size: 9pt;
	text-align: center;
	background-color: #eee;
	font-family: 'Roboto', sans-serif;
	cursor: pointer;
	transition: background-color 0.3s;
}

.EditReleaseLinkTest a {
	transition: color 0.3s;
	color: #333;
}

.EditReleaseLinkTest:hover {
	background-color: #ddd;
}

.EditReleaseLinkTest:hover a {
	color: orange;
}

.EditReleaseLinkUrl input, .EditReleaseLinkAction input {
	height: 100%;
	width: 100%;
	padding: 10px;
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	border: 0;
	font-size: 9pt;
}

.EditReleaseLinkPlatform input {
	height: calc(100% + 1px);
	width: 100%;
	padding: 10px;
	margin: 0;
	top: -1px;
	border-radius: 0;
	box-shadow: none;
	border: 0;
	background-color: rgba(255, 255, 255, 0.3);
	font-size: 10pt;
	color: #eee;
	letter-spacing: 1px;
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
}

.EditReleaseLinkAction {
	border-left: 1px dotted #ccc;
}

.LoaderWrapper {
	width: 100%;
	padding: 10px;
}

.ReleaseAutoDetectLoader {
	border: 10px solid #fafafa;
	border-radius: 50%;
	border-top: 10px solid orange;
	width: 50px;
	height: 50px;
	left: calc(50% - 25px);
	-webkit-animation: spin 1s linear infinite; /* Safari */
	animation: spin 1s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
	0% { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
  }

.EditReleaseAutodetect {
	text-align: right;
	width: 100%;
	margin-bottom: 10px;
}

.EditReleaseAutodetect .Button {	
	display: inline-block;
}

.EditReleaseAutodetect .Inactive {
	pointer-events: all;
	cursor: not-allowed;
}

.EditReleaseAutodetectPoweredBy {
	font-size: 8pt;
	font-style: italic;
	padding: 5px;
}

.EditReleaseAddLink input {
	display: inline-block;
	width: 750px;
	margin-right: 20px;
	padding: 5px 20px;
	line-height: 30px;
	height: 35px;
	vertical-align: top;
}

.EditReleaseAddLink .Button {
	display: inline-block;
}

.EditReleaseTracks {
	width: 920px;
}

.EditReleaseTrackListHeaders {
	width: 100%;
	background-color: #666;
	box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.6);
	margin: 0 0 10px 0;
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
}

.EditReleaseTrackList {
	width: 100%;
}

.EditReleaseTrack {
	width: 100%;
	font-family: "Roboto", sans-serif;
	font-size: 8pt;
	margin: 5px 0;
	box-shadow: 2px 2px 3px rgba(0,0,0,0.05);
	background-color: #fafafa;
	line-height: 25px;
	border: 1px solid #eee;
	color: #333;
}

.TrackShowDetails {
	position: absolute;
	top: 0;
	right: 0;
	width: 35px;
	height: 27px;
	line-height: 27px;
	text-align: center;
	background-color: #fafafa;
	font-size: 12pt;
	color: #888;
	box-shadow: 2px 2px 3px rgba(0,0,0,0.05);
	cursor: pointer;
	z-index: 3;
	transition: color 0.3s;
}

.TrackShowDetails:hover {
	color: #333;
}

.TrackRemove {
	position: absolute;
	top: 0;
	right: -45px;
	width: 30px;
	height: 27px;
	line-height: 27px;
	text-align: center;
	background-color: #fafafa;
	font-size: 12pt;
	color: red;
	box-shadow: 2px 2px 3px rgba(0,0,0,0.05);
	cursor: pointer;
	transition: background-color 0.2s;
}

.TrackRemove:hover {
	background-color: #eee;
}

.EditReleaseTrackHandle {
	vertical-align: top;
	display: inline-block;
	font-size: 12pt;	
	width: 27px;
	text-align: center;
	color: #ddd;
	cursor: move;
	user-select: none;
	border-right: 1px dashed #ddd;
}

.EditReleaseTrackNumber {
	display: inline-block;
	width: 30px;
	text-align: center;	
	color: #888;
	pointer-events: none;
}

.EditReleaseTrackFilename {
	vertical-align: top;
	display: inline-block;
	width: 370px;
	text-align: left;
	color: #888;
	pointer-events: none;
	overflow: hidden;
}

.EditReleaseTrackName {
	display: inline-block;
	width: 227px;
	text-align: left;
	border-left: 1px dashed #ddd;
}

.EditReleaseTrackArtists {
	display: inline-block;
	width: 227px;
	text-align: left;
	border-left: 1px dashed #ddd;
	border-right: 1px dashed #ddd;
}

.EditReleaseTrackName input, .EditReleaseTrackArtists input {
	width: 100%;
	height: 25px;
	border: none;
	box-shadow: none;
	padding: 0 5px;
	line-height: 25px;
	font-size: 8pt;
}

.EditReleaseTrackDownloadable, .EditReleaseTrackListen, .EditReleaseTrackCover {
	display: inline-block;
	width: 65px;
	text-align: center;
	vertical-align: middle;
}

.EditReleaseTrackDownloadable input, .EditReleaseTrackListen input, .EditReleaseTrackCover input {
	width: 15px;
	height: 12px;
	padding: 0;
	margin: 0;
	line-height: 27px;
	text-shadow: none;
	box-shadow: none;
}

.EditReleaseTrackDetails {
	border-top: 1px dashed #eee;
	padding: 5px 20px 5px 28px;
}

.EditReleaseTrackDetail {
	margin: 4px 0;
	height: 30px;
}

.EditReleaseTrackDetailTitle {
	font-family: 'Lato', sans-serif;
	box-shadow: 1px 2px 3px 0px rgba(0,0,0,0.4);
	z-index: 1;
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 149px;
	height: 100%;
	font-size: 9pt;
	background-color: #666;
	color: #eee;
	padding: 0 2px 0 10px;
	letter-spacing: 1px;
	-webkit-user-select: none;  /* Chrome all / Safari all */
	-moz-user-select: none;     /* Firefox all */
	-ms-user-select: none;      /* IE 10+ */
	user-select: none;          /* Likely future */
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
}

.EditReleaseTrackDetailValue {
	display: inline-block;
	height: 100%;
}

.EditReleaseTrackDetailValue input[type="text"] {
	height: 100%;
	width: 248px;
	padding: 10px;
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	border: 0;
}

.EditReleaseTrackDetailValue input[type="checkbox"] {
	height: 20px;
	width: 20px;
	margin: 5px 10px;
	border-radius: 0;
	box-shadow: none;
	border: 0;
}

.Header {
	font-family: "Lato", sans-serif;
	color: #eee;
	font-size: 8pt;
	text-transform: uppercase;
	background-color: #666;
	border: none;
	line-height: 18px;
	vertical-align: middle;
}

#ButtonUploadTracks {
	position: absolute;
	top: 1px;
	right: -30px;
	z-index: 1;
	width: 150px;
	border-radius: 50px;
	text-align: right;
	font-size: 9pt;
}

#ButtonUploadTracks .fa {
	font-size: 11pt;
}

#ButtonUploadTracks:hover {
	right: -80px;
	color: #fff;
}

.EditReleaseTracksUpload {
	width: 100%;
}

.EditReleaseTracksUpload input {
	display: none;
}

.EditReleaseTracksUpload .Button {
	margin: 0 auto;
}

.EditReleaseTracksUploadPreview {

}

.ProgressBar {
	position: absolute;
	width: 100%;
	height: 27px;
	left: 0;
	top: 0;
	pointer-events: none;
	z-index: 2;
}

.Progress {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	transition: width 0.1s;
}