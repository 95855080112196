.Profile {
	width: 940px;
	height: 100%;
	margin: 0 auto;
}

.ProfileLogo {
	width: 250px;
	height: 250px;
	box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.3);
	background-color: #eee;
}

.ProfileLogo input {
	display: none;
}

.ProfileLogoImage {
	width: 100%;
	height: 100%;
	line-height: 250px;
	text-align: center;
	color: #fff;
	font-size: 140pt;
	cursor: pointer;
}

.ProfileLogoImage img {
	width: 100%;
	height: 100%;
}

.ProfileLogoImageChange {
	font-family: 'Lato', sans-serif;
	position: absolute;
	bottom: 25px;
	left: -3px;
	width: calc(100% + 6px);
	line-height: 30px;
	text-align: center;		
	color: #333;
	font-size: 9pt;
	text-transform: uppercase;
	text-shadow: none;
	background-color: rgba(255,255,255,0.8);
	box-shadow: 0px 3px 2px 0px rgba(0,0,0,0.3);
}

.ProfileMeta {
	width: 650px;
}

.ProfileField {
	position: relative;
	line-height: 35px;
	margin: 0 0 5px 0;
	box-shadow: 2px 2px 2px #eee;
}

.ProfileField:last-child {
	border: none;
}

.ProfileDescription {
	height: 100px;
}

.ProfileLabel {
	font-family: 'Lato', sans-serif;
	box-shadow: 1px 2px 3px 0px rgba(0,0,0,0.4);
	z-index: 1;
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 149px;
	height: 100%;
	font-size: 9pt;
	background-color: #666;
	color: #eee;
	padding: 0 2px 0 10px;
	letter-spacing: 1px;
	-webkit-user-select: none;  /* Chrome all / Safari all */
	-moz-user-select: none;     /* Firefox all */
	-ms-user-select: none;      /* IE 10+ */
	user-select: none;          /* Likely future */
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
}

.ProfileLabel .fab {
    padding-right: 15px;
    width: 30px;
}

.ProfileLabelText {
	display: inline-block;
	width: calc(100% - 20px);
	vertical-align: middle;
}

.ProfileLabelInfo {
	display: inline-block;
	width: 20px;
	cursor: help;
	font-size: 11pt;
	vertical-align: middle;
}

.ProfileDescription .ProfileLabel {
	width: 149px;
	height: 99px;
	line-height: 99px;
}

.ProfileInput {
	position: relative;
	display: inline-block;
	width: calc(100% - 150px);
	height: 100%;
	font-size: 9pt;
}

.ProfileInput input {
	height: 100%;
	width: 100%;
	padding: 10px;
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	border: 0;
}

.ProfileInput textarea {
	height: 99px;
	width: 100%;
	padding: 10px;
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	border: 0;
    resize: none;
    font-family: 'Roboto', sans-serif;
}
