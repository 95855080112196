.LinkPageWrapper {
    background-color: white;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;
}

.LinkPageBackground {
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.LinkPage {
    min-width: 320px;
    max-width: 500px;
    min-height: 100vh;
    margin: 0 auto;
    font-family: 'Lato', sans-serif;
    text-align: center;
    background-color: #111111;
    color: #eee;
    box-shadow: 0 -3px 5px 2px rgba(0, 0, 0, 0.3);
    padding-bottom: 20px;
    filter: blur(0);
    opacity: 0.95;
}

.LinkPageUserInfo {
    width: 100%;
}

.LinkPageUserLogo {
    position: relative;
    width: 100%;
    padding: 20px;
    margin: 0;
}

.LinkPageUserLogo img {
    width: 100%;
    height: auto;
    padding: 0;
    margin-bottom: -5px;
}

.LinkPageUserName {    
    font-size: 27pt;
    height: 60px;
    line-height: 60px;
    background-color: darkorange;
    margin: 0;
}


.LinkPageUserSocials {
    width: 100%;
    text-align: center;
    color: #eee !important;    
}

.LinkPageUserSocials a {
    font-size: 12pt;
    transition: all 0.3s;
    color: #eee;
}

.LinkPageUserSocials a:hover {
    color: orange;
}

.LinkPageUserSocial {
    border-bottom: 1px solid #aaa;
    background-color: #111;
    font-size: 17pt;
    cursor: pointer;
    transition: all 0.3s;
    padding: 15px;
    text-align: left;
}

.LinkPageUserSocial span {
    width: 50px;
    text-align: center;
}

.LinkPageUserSocial:hover {
    color: orange;
    background-color: #222;
}

.LinkPageUserDescription {
    text-align: justify;
    padding: 0 30px;
    font-size: 10pt;
    line-height: 20px;
    margin-top: 50px;
}

.LinkPageCustomLinks {
    width: 100%;
    margin: 0 0 15px 0;
    text-align: center;
    color: #eee !important;  
}

.LinkPageCustomLink {
    border-bottom: 1px solid #aaa;
    background-color: #111;
    font-size: 17pt;
    cursor: pointer;
    transition: all 0.3s;
    padding: 15px;
    text-align: left;
}

.LinkPageCustomLink span {
    width: 50px;
    text-align: center;
}

.LinkPageCustomLink:hover {
    color: orange;
    background-color: #222;
}