.Landing {
	width: 100%;
	height: 100%;
	color: #333;	
	background-color: #fafafa;
}

.LandingPage {
	width: 100%;
	height: 100vh;
	padding-top: 65px;
	padding-bottom: 90px;
}

.LandingLeftMenu {
	margin-left: 150px;
	height: 65px;
	float: left;
}

.LandingMenu {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 65px;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ddd;	
	font-size: 10pt;
	z-index: 3;	
}

.LandingLeftMenuItem {
	font-family: 'Lato', sans-serif;
	display: inline-block;
	text-transform: uppercase;
	cursor: pointer;
	margin-right: 40px;
	line-height: 65px;
	transition: color 0.3s;
}

.Selected {
	color: darkorange;
}


.LandingRightMenu {
	margin-right: 150px;
	height: 65px;
	float: right;
}

.LandingRightMenuItem {
	font-family: 'Lato', sans-serif;
	display: inline-block;
	text-transform: uppercase;
	cursor: pointer;
	margin-left: 40px;
	line-height: 65px;
	transition: color 0.3s;
}

.LandingLeftMenuItem:hover, .LandingRightMenuItem:hover {
	color: orange;
}

.OrangeMenuItem {
	color: orange;
}

.OrangeMenuItem:hover {
	color: darkorange;
}

.LandingPageBottom {
	cursor: pointer;
	text-align: center;	
	font-family: 'Roboto', sans-serif;	
	color: #eee;
	transition: color 0.3s;
	background-color: #222;
	box-shadow: 0px -4px 8px rgba(0,0,0,0.3), 0px 4px 8px rgba(0,0,0,0.3);
	border-top: 1px solid black;
	padding: 15px 0 10px 0;
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 90px;
	z-index: 3;
}

.LandingPageBottom:hover .LandingPageBottomChevron {
	color: orange;
}

.LandingPageBottom:hover .LandingPageBottomLinkText {
	color: #eee;
}

.LandingPageBottomLinkText {
	font-size: 9pt;
	margin-bottom: 10px;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.LandingPageBottomChevron {
	font-size: 25pt;
	transition: color 0.3s;
	text-shadow: 1px 1px 4px rgba(0,0,0,0.7), -1px -1px 3px rgba(0,0,0,0.2);
	color: #fff;
}

.LandingPageTitle {
	font-family: 'Lato', sans-serif;
	font-size: 30pt;
	font-style: italic;
	text-shadow: 0px 2px 3px rgba(0,0,0,0.6);
	position: absolute;	
	text-align: center;
	width: 300px;
	height: 80px;
	left: 0;
	top: calc(50% + 130px);
	transform: rotate(270deg);
	transform-origin: top left;
	background-color: #222;
	line-height: 80px;
	color: #eee;
	box-shadow: -2px 2px 2px rgba(0,0,0,0.2);
}

#Overview, #Features {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.LandingHeader {
	position: relative;
	width: 100%;
	height: 100%;
	background-image: url("/eg_banner.webp");
	background-size: cover;
	background-position: center right;
	box-shadow: -2px 2px 5px rgba(0,0,0,0.2);
	overflow: hidden;
}

.LandingHeader video {
	position: relative;
	min-width: 100%;
	min-height: 100%;
	float: right;
	overflow: hidden;
}
					
.LandingHeaderText {
	display: none;
	position: absolute;
	bottom: 90px;
	width: 100%;
	background: rgba(255,255,255,0.8);
	padding: 20px 0;
}

.LandingHeaderTextLeft {
	width: 100%;
	text-align: center;
	font-family: 'Roboto', sans-serif;
	font-style: italic;
	text-shadow: -2px 2px 8px rgba(0,0,0,0.2);
	
}

.LandingHeaderTextRight {
	display: none;
}

.LandingLogin .Form .Message {	
	color: green;
	font-size: 11pt;
}


.LandingOverviewCarousel {
	width: 500px;
	height: 565px;
	display: inline-block;
	vertical-align: top;
	margin-right: 100px;
}

.LandingOverviewImage {
	height: 500px;
	width: 503px;
	background-image: url("/overviewsquare.webp");
	display: inline-block;
	vertical-align: top;
	margin-top: -72px;
}

.Features {
	width: 1000px;
	text-transform: capitalize;
	font-family: 'Lato', sans-serif;
}

.FeatureHeaders {
	display: block;
	width: 100%;
}

.FeatureCategory {
	width: 300px;
	display: inline-block;
	text-align: center;
	color: green;
}

.FeatureHeaders .FeatureCategory {
	color: black;
	font-weight: bold;
}

.FeatureCategoryText {
	color: black;
}

.FeatureCategoryPrice {
	display: none;
}

.Feature {
	display: block;
	width: 100%;
	border-bottom: 1px solid black;
	padding: 3px 0;
}

.Feature:last-child {
	border-bottom: none;
}

.FeatureName {
	width: 400px;
	display: inline-block;
	vertical-align: middle;
	text-transform: none;
}

.FeatureTitle {
	font-size: 13pt;
	margin-bottom: 3px;
}

.FeatureDescription {
	text-transform: none;
	font-size: 10pt;
}

.FeatureTitle {
	color: #111;
	font-family: 'Lato', sans-serif;
	font-weight: bold;
}

.PricingPlans {
	width: 80%;
	max-width: 1100px;
	height: 100%;
	margin: auto;
	text-align: center;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.PricingPlanOuter {
	display: inline-block;
	vertical-align: top;
	width: 26%;
	margin: 0 2%;
	padding: 3px;
	border: 4px solid #fafafa;
}

.PricingPlanOuterChosen {
	border: 4px solid orange;
}

.PricingPlanInner {
	width: 100%;
	border: 1px solid #ccc;
}

.PricingPlanTitle {
	font-family: 'Lato', sans-serif;
	padding: 10px;
	text-transform: uppercase;
	background-color: orange;
	color: white;
}

.PricingPlanFeatures {
	font-family: 'Roboto', sans-serif;
}

.PricingPlanFeature {
	border-bottom: 1px solid #ddd;
}

.PricingPlanPrice {
	font-family: 'Lato', sans-serif;
	background-color: #333;
	color: white;
	padding: 10px;
}

.LandingOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.8);
	transition: all 0.3s;
	z-index: 4;
}


@media only screen and (max-width: 1279px) {

	.LandingPage {
		width: 100%;
		height: 100vh;
	}

	.LandingPageBottom {
		display: none;
	}
	
	.LandingRightMenuItem {
		font-family: 'Lato', sans-serif;
		display: inline-block;
		text-transform: uppercase;
		cursor: pointer;
		margin-right: 20px;
		line-height: 65px;
		transition: color 0.3s;
	}

	.LandingRightMenuItem:last-child {
		margin-right: 0;
	}


	.LandingMenu {
		background-color: rgba(255,165,0,0.9);
		color: #fafafa;
		height: 50px;
	}

	.LandingLeftMenu {
		display: none;
	}    
	
	.LandingRightMenu {
		margin-right: 25px;
		height: 50px;
	}

	.LandingRightMenuItem {
		margin-left: 0;
		line-height: 50px;
	}


	.LandingPageTitle {
		display: none;
	}

	.LandingHeader {
		background-image: url("/overviewsquare.webp");
		background-size: contain;
		background-repeat: no-repeat;
		box-shadow: none;
	}

	.LandingHeader video {
		display: none;
	}

	.LandingHeaderText {
		width: 100%;
		height: 100%;
	}

	.LandingHeaderTextLeft {
		display: none;
	}

	.LandingHeaderTextRight {
		width: 100%;
		height: 100%;
		display: block;
	}

	.LandingLogin .Form .Message {	
		color: green;
		font-size: 11pt;
	}

	.LandingLogin {
		display: none;
		padding-top: 20px;
	}

	.Form {
		width: 90%;
	}

	.LandingOverviewCarousel {
		padding-top: 50px;
		width: 90%;
		height: 400px;
		display: block;
		margin-right: 0;
	}

	.LandingOverviewImage {
		display: none;
	}

	
	.Features {
		width: 90%;
		text-transform: capitalize;
		font-family: 'Lato', sans-serif;
		padding-top: 50px;
	}

	.FeatureHeaders {
		display: block;
		width: 100%;
	}

	.FeatureCategory {
		width: 33%;
		display: inline-block;
		text-align: center;
		color: green;
	}

	.FeatureHeaders .FeatureCategory {
		color: black;
		font-weight: bold;
	}

	.FeatureCategoryName {
		font-size: 8pt;
	}

	.FeatureCategoryText {
		color: black;
		font-size: 8pt;
	}

	.FeatureCategoryPrice {
		display: none;
	}

	.Feature {
		display: block;
		width: 100%;
		border-bottom: 1px solid black;
		padding: 1px 0;
	}

	.Feature:last-child {
		border-bottom: none;
	}

	.FeatureName {
		width: 33%;
		display: inline-block;
		vertical-align: middle;
		text-transform: none;
	}

	.FeatureTitle {
		font-size: 9pt;
		margin-bottom: 3px;
	}

	.FeatureDescription {
		display: none;
	}

	.FeatureTitle {
		color: #111;
		font-family: 'Lato', sans-serif;
		font-weight: bold;
	}

	.LandingOverlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.8);
		transition: all 0.3s;
		z-index: 4;
	}

}

@media only screen and (max-width: 2000px) and (min-width: 1280px) {

	.Landing {
		min-width: 1280px;	
	}

	.LandingMenu {
		background-color: rgba(255,255,255,0.9);
	}

	.LandingLeftMenu {
		margin-left: 150px;
	}

	.LandingRightMenu {
		margin-right: 150px;
	}

	.LandingHeaderTextLeft {
		font-size: 30pt;
	}

	.LandingLogin {
		display: none;
		position: absolute;
		bottom: 50px;
		right: 40px;
		background-color: rgba(255,255,255,0.9);
		padding: 15px;
		box-shadow: 1px 2px 5px rgba(0,0,0,0.2);
		border-radius: 5px;
	}

	.LandingLogin .Form {
		font-family: 'Lato', sans-serif;
		font-size: 10pt;
	}

	.LandingLogin .FormField input {
		height: 35px;
		padding: 5px;
	}

	.LandingLogin .Form .Button {	
		margin-bottom: 3px;
	}
							
	.LandingHeaderRequestBeta {

	}
}

@media only screen and (min-width: 2001px) {

	.Landing {
		min-width: 1280px;	
	}

	.LandingMenu {
		background-color: rgba(255,255,255,0.9);
	}

	.LandingLeftMenu {
		margin-left: 150px;
	}

	.LandingRightMenu {
		margin-right: 150px;
	}

	.LandingHeaderTextLeft {
		font-size: 50pt;
	}

	.LandingLogin {
		display: none;
	}

	.LandingLogin .Form {
		font-family: 'Lato', sans-serif;
		font-size: 10pt;
	}

	.LandingLogin .FormField input {
	}

	.LandingLogin .Form .Button {	
		margin-bottom: 3px;
	}
							
	.LandingHeaderRequestBeta {

	}
}


