.Subscribers {
	width: 972px;
	height: 100%;
	margin: 0 auto;
}

.SubscriberTable {
	width: 972px;
	margin-bottom: 10px;
}

.ReactTable {
	width: 100%;
	font-size: 9pt;
	font-family: "Lato", sans-serif !important;
}

.-header {
	text-transform: uppercase;
	font-size: 8pt;
}

.-pageJump input {
	height: auto;
}

.-pageSizeOptions {
	display: inline-block;
}

.SubscriberListDelete {
	text-align: center;
	color: red;
	cursor: pointer;
}

.SubscribersExtra {
	width: 100%;
}

.SubscribersExtra input#presskit, .SubscribersExtra input#newsletter {
	display: none;
}

.SubscriberAdd {
	display: inline-block;
}

.SubscriberRemoveAll {
	position: absolute;
	bottom: -60px;
	background-color: red;
	color: white;
	padding: 10px;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
	box-shadow: 0px 0px 2px #9E5800;
	border-radius: 5px;
	cursor: pointer;
	transition: all 0.3s;
}

.SubscriberRemoveAll:hover {
	background-color: darkred;
}

.SubscriberImportHelp {
	position: absolute;
	top: 15px;
	right: -50px;
	font-size: 20pt;
	cursor: pointer;
	color: orange;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
}

.SubscriberImportHelp:hover {	
	text-shadow: 2px 1px 2px rgba(0,0,0,0.3);
}

.SubscriberAdd .Button {
	display: inline-block;
	width: 75px;
	height: 25px;
	line-height: 25px;
	padding: 0;
	vertical-align: bottom;
	border-radius: 2px;
	margin-left: 4px;
	top: 1px;
}

.SubscriberAddTitle {
	width: 100%;
	background-color: #999;
	color: #eee;
	font-size: 9pt;
	font-family: "Lato", sans-serif !important;
	height: 30px;
	line-height: 30px;
	text-align: center;
	margin-bottom: 5px;
	box-shadow: 2px 2px 2px rgba(0,0,0,0.3);
}

.SubscriberField {
	display: inline-block;
	box-shadow: 2px 2px 2px rgba(0,0,0,0.1);
	margin-right: 5px;
	vertical-align: bottom;
}

.SubscriberField:last-child {
	margin: 0;
}

.SubscriberFieldTitle {
	font-family: 'Lato', sans-serif;
	box-shadow: 1px 2px 3px 0px rgba(0,0,0,0.4);
	z-index: 1;
	position: relative;
	display: inline-block;
	vertical-align: top;
	height: 100%;
	font-size: 9pt;
	background-color: #666;
	color: #eee;
	padding: 0 10px 0 10px;
	letter-spacing: 1px;
	-webkit-user-select: none;  /* Chrome all / Safari all */
	-moz-user-select: none;     /* Firefox all */
	-ms-user-select: none;      /* IE 10+ */
	user-select: none;          /* Likely future */
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
	height: 25px;
	line-height: 25px;
}

.SubscriberFieldInput {
	height: 25px;
	line-height: 25px;
	display: inline-block;
	vertical-align: middle;
}

.SubscriberFieldInput input {
	height: 25px;
	line-height: 25px;
	box-shadow: none;
	padding: 0 10px;
	border: none;
}

.SubscriberImportExport {	
	vertical-align: top;
	float: right;
	padding-left: 10px;	
}

.SubscriberImport, .SubscriberExport {	
	vertical-align: top;
	background-color: #888;
	box-shadow: 1px 1px 2px rgba(0,0,0,0.3);
	cursor: pointer;
	color: #eee;
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 3px rgba(0,0,0,0.5);
	transition: color 0.3s, background-color 0.3s;
}

.SubscriberImport {
	margin-bottom: 10px;
}

.SubscriberImport:hover, .SubscriberExport:hover {
	color: orange;
	background-color: #666;
}

.SubscriberImportIcon {
	display: inline-block;
	width: 63px;
	height: 60px;
	line-height: 60px;
	text-align: center;
	font-size: 30pt;
	vertical-align: middle;		
}

.SubscriberImportText  {
	display: inline-block;
	width: 70px;
	height: 60px;
	line-height: 20px;
	padding-top: 10px;
	text-align: center;
	vertical-align: middle;
	font-size: 9pt;
	text-transform: uppercase;
}

.CsvHelp p {
	padding: 20px 50px;
	font-size: 11pt;
}

.CsvHelp table {
	padding: 20px 50px;
}

.CsvSample {
	background-color: #eee;
	margin: 0 40px 20px 40px;
	font-family: monospace;
	font-size: 10pt;
	padding-top: 0;
}

.Descriptive {
	color: #999;
	font-style: italic;
	font-size: 9pt;
}



