.Releases {
	width: 972px;
	height: 100%;
	margin: 0 auto;
}


.ReleasesEmpty {
    font-size: 14pt;
    padding: 20px 0;
    text-align: left;
}


.ReleasesNewRelease {
    margin: 20px 0;
    padding: 10px 20px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.3);
    width: 370px;
    cursor: pointer;
    background-color: orange;
    color: #eee;
    border-radius: 5px;
    opacity: 0.8;
    transition: opacity 0.3s;
}

.ReleasesNewRelease:hover {
    opacity: 1;
}

.ReleasesNewReleaseLogo {
    font-size: 50pt;
    display: inline-block;
    width: 70px;
    vertical-align: middle;
}

.ReleasesNewReleaseText {
    font-size: 20pt;
    display: inline-block;    
}

.Releases .DashboardComponentSection {
	width: 100%;
}

.ReleasesNoActive {
	padding: 20px 0;
	font-size: 14pt;
	text-align: center;
}

.ReleaseList {
	width: 972px;
}

.Release {
	display: inline-block;
	height: 230px;
	margin: 10px 10px 10px 0;
	width: calc(50% - 10px);
}

.Release:nth-child(even) {
	margin: 10px 0 10px 10px;
}

.WithStats {
	width: 100%;
	margin: 10px 0;
}

.WithStats:nth-child(even) {
	margin: 10px 0;
}

.ReleaseLeft {	
	min-width: 476px;
	display: inline-block;
	vertical-align: top;	
}

.WithStats .ReleaseLeft {
	margin-right: 10px;
}

.ReleaseRight {
	width: calc(50% - 10px);
	height: 100%;
	margin-left: 10px;
	display: inline-block;
	vertical-align: top;
}


.ReleaseCover {
	width: 150px;
	height: 150px;
	box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.3);
	display: inline-block;
	vertical-align: top;
	line-height: 150px;
	text-align: center;
	font-size: 100pt;
	background-color: #eee;
	color: #fff;
	cursor: pointer;
}

.ReleaseCover img {
	width: 100%;
	height: 100%;
}

.ReleaseInfo {
	display: inline-block;
	height: 150px;
	vertical-align: top;
	margin-left: 6px;
}

.ReleaseField {
	margin: 0 0 6px 0;
	box-shadow: 1px 2px 3px 0px rgba(0,0,0,0.4);
	background-color: #fff;
}

.ReleaseFieldTitle {
	font-family: 'Lato', sans-serif;
	box-shadow: 1px 2px 3px 0px rgba(0,0,0,0.4);
	z-index: 1;
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 100px;
	height: 100%;
	font-size: 9pt;
	background-color: #666;
	color: #eee;
	padding: 5px 10px;
	letter-spacing: 1px;
	-webkit-user-select: none;  /* Chrome all / Safari all */
	-moz-user-select: none;     /* Firefox all */
	-ms-user-select: none;      /* IE 10+ */
	user-select: none;          /* Likely future */
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
}

.ReleaseFieldValue {
	position: relative;
	display: inline-block;
	width: 220px;
	height: 100%;
	font-size: 9pt;
	padding: 5px 10px;
}

.ReleaseBottom {
	width: 100%;
	height: 30px;
	margin-top: 10px;
}

.ReleaseActions {
	width: 100%;
	height: 30px;
	margin-top: 10px;
}

.ReleaseAction {
	font-family: 'Lato', sans-serif;
	min-width: 30px;
	height: 30px;
	line-height: 30px;
	display: inline-block;
	text-align: center;
	vertical-align: middle;
	box-shadow: 1px 2px 3px 0px rgba(0,0,0,0.4);
	padding: 0 10px;
	margin-right: 5px;
	cursor: pointer;
	color: #333;
	transition: color 0.3s, background-color 0.3s;
	font-size: 10pt;
	text-transform: uppercase;
	background-color: #eee;
}

.ReleaseAction .fa {
	padding-right: 10px;
}

.ReleaseAction:hover {
	color: #666;
	background-color: #ddd;
}

.Delete {
	color: red;
}

.Delete:hover {
	color: darkred;
}

.ReleaseLinks {
	width: 100%;
	height: 30px;
	box-shadow: 1px 2px 3px 0px rgba(0,0,0,0.4);		
	padding: 0 10px;
}

.ReleaseLink {
	padding: 0 5px;
	line-height: 30px;	
	font-size: 14pt;
	display: inline-block;
	vertical-align: bottom;
}

.ReleaseLink a {
	color: #666;
	transition: color 0.3s;
}

.ReleaseLink a:hover {
	color: orange;
}

.ReleaseStats {
	width: 100%;
	height: 100%;
	box-shadow: 1px 2px 3px 0px rgba(0,0,0,0.4);
}

.ReleaseStatsEmpty {
	text-align: center;
	line-height: 230px;
	font-size: 100pt;
	color: #eee;
}

.ReleaseStatsText {
	font-family: 'Lato', sans-serif;
	text-transform: uppercase;
	font-size: 9pt;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	line-height: 190px;
}