.Feed {
    width: 100%;
    position: relative;
}

.FeedHeader {
    width: 100%;
    height: 50px;
    background-color: #333;    
    position: fixed;
    z-index: 2;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
}

.FeedHeaderMenuButton {
    width: 50px;
    font-size: 23pt;
    line-height: 50px;
    margin-left: 10px;
    cursor: pointer;
    color: orange;
    transition: color 0.3s;
}

.FeedHeaderMenuButton:hover {
    color: lightsalmon;
}

.FeedHeaderMenu {    
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 50px;
    left: 0;
	z-index: 1;
	width: 300px;
}

.FeedHeaderMenuItem {
    font-family: 'Lato', sans-serif;
    border-bottom: 1px solid #555;
    padding: 15px 30px;
    color: #eee;
    cursor: pointer;
    background-color: #333;
    transition: background-color 0.3s;
}

.FeedHeaderMenuItem:hover {
    background-color: #444;
}

.NoClick {
    cursor: default;
}

.NoClick:hover {
    background-color: #333;
}

.Special {
    background-color: orange;
}

.Special:hover {
    background-color: lightsalmon;
}

.FeedHeaderMenuItem:last-child {
    border-bottom: none;
}

.FeedReleases {
    top: 50px;
}

.FilterInput {
	position: relative;	
	width: 100%;
	height: 100%;
    font-size: 9pt;
    margin-top: 5px;
}

.FilterInput input {
    height: 100%;
	width: 100%;
	padding: 10px;
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	border: 0;
}

.ReactTags__tags {
	height: 100%;
	line-height: 35px;
}

.FeedHeaderMenu .ReactTags__tagInput {
	display: block;
}

.ReactTags__tagInputField {
	width: 100%;
}
.ReactTags__selected {
    padding-left: 0;
}

.ReactTags__selected .ReactTags__tag {
	font-family: 'Lato', sans-serif;
	background-color: orange;
	font-size: 10pt;
	height: 25px;
	line-height: 25px;
	color: #eee;
	padding: 0 0 0 10px;
	margin: 0 2px;
	border-radius: 3px;
	vertical-align: middle;
}

.ReactTags__selected .ReactTags__remove {
	color: #fff;
	margin-left: 8px;
	height: 25px;
	line-height: 25px;
	font-size: 12pt;
	cursor: pointer;
	border-left: 1px dashed #eee;
	padding: 0 5px;
	display: inline-block;
	background-color: rgba(0,0,0,0.1);
	border-radius: 0 3px 3px 0;
}

.ReactTags__suggestions {
	position: absolute;
	z-index: 5;	
	background: #eee;
	color: #333;
	overflow: hidden;
	cursor: pointer;
	padding: 0;
}

.ReactTags__suggestions li {
	padding: 3px 10px;	
	transition: background-color 0.2s;
	margin: 0 3px;
	box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.1);
}

.ReactTags__activeSuggestion {
	background-color: #fafafa;
}


