.EditCatalog {
    width: 940px;
	height: 100%;
	margin: 0 auto;
}

.CatalogPreview {
    display: inline-block;
    width: 630px;
    max-height: 70vh;
    overflow-y: scroll;
    box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.4);
    border: 1px solid #eee;
}

.CatalogEdit {
    display: inline-block;
    width: 270px;
    box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.4);
    border: 1px solid #eee;
    min-height: 70vh;
}

.PublicUrl {

}

.PublicUrlTitle {
    display: inline-block;
    vertical-align: top;
    padding: 0 10px;
    background-color: #444;
    color: #eee;
    margin-bottom: 7px;
    text-shadow: 0px 1px 0px rgba(0,0,0,0.8), 0px 1px 2px rgba(0,0,0,0.5), 0px 2px 6px rgba(0,0,0,0.5);
    -webkit-box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.4);
    box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.4);
    z-index: 2;
    font-size: 11pt;
    height: 35px;
    line-height: 35px;
    margin-right: -4px;
}

.PublicUrlInput {
    display: inline-block;
    vertical-align: top;
}

.PublicUrlInput input {
    height: 35px;
    line-height: 35px;
    padding: 0;
    width: 400px;
    margin-right: 15px;
    padding: 0 10px;
}

.PublicUrlButton {
    display: inline-block;
    vertical-align: top;
}

.CatalogUploadImage input {
    display: none;
}

.CatalogImage {
	text-align: center;
	color: #fff;
    cursor: pointer;
    width: 220px;
    margin: 5px auto;
}

.CatalogImageChange {
    font-family: 'Lato', sans-serif;	
	line-height: 30px;
	text-align: center;		
	color: #333;
	font-size: 9pt;
	text-transform: uppercase;
	text-shadow: none;
	background-color: rgba(255,255,255,0.8);
    box-shadow: 0px 3px 2px 0px rgba(0,0,0,0.3);
    transition: all 0.3s;
}

.CatalogImageChange:hover {
	background-color: #ddd;
}

