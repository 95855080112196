
@media only screen and (max-width: 479px) {
    .FeedRelease {
        width: 98%;
    }
}


@media only screen and (min-width: 480px) and (max-width: 1000px) {
    .FeedRelease {
        width: 48%;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1680px) {
    .FeedRelease {
        width: 31%;
    }
}

@media only screen and (min-width: 1681px) {
    .FeedRelease {
        width: 23%;
    }
}

.FeedRelease {
    display: inline-block;
    margin: 1%;
    cursor: pointer;
    vertical-align: top;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.1);
}

.FeedRelease:hover .FeedReleaseTitle {
    background-color: #333;
}

.FeedReleaseCover {
    width: 100%;
}

.FeedReleaseCover img {
    width: 100%;
    height: auto;
}

.FeedReleaseInfo {
    width: 100%;
}

.FeedReleaseTitle {
    font-family: 'Lato', sans-serif;
    background-color: #333;
    color: #eee;
    padding: 7px;
    font-size: 12pt;
    text-align: center;
    transition: all 0.3s;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.3);
}

.FeedReleaseArtist {
    font-family: 'Lato', sans-serif;
    text-align: center;
    font-size: 10pt;
    padding: 5px;
}