.CarouselInner {
    height: 100%;
    width: 100%;
    margin: 0;
}

.CarouselElements {
    overflow: hidden;   
    width: 100%;
    height: 87%;
    border-radius: 7px;
    background-image: linear-gradient(#ffe4c7, #ffffff);
}

.CarouselElement {   
    text-align: center;   
    height: 100%;
    width: 100%;    
}

.CarouselElementImage {
    height: 85%;
    padding: 20px 0;
    
}

.CarouselElementImage video {
    height: 100%;
    width: auto;
}

.CarouselElementTitle {
    color: #eee;
    font-family: 'Lato', sans-serif;
    font-size: 25pt;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    padding: 17px 0;
    height: 15%;
    background-color: #222;
}

.CarouselLeftArrow, .CarouselRightArrow {
    position: absolute;
    font-size: 30pt;
    cursor: pointer;
    color: #aaa;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
    transition: color 0.3s;
    bottom: 14%;
    height: 50px;
    z-index: 100;
}

.CarouselLeftArrow:hover, .CarouselRightArrow:hover {
    color: orange;
}

.CarouselLeftArrow {    
    left: 20px;
}

.CarouselRightArrow {
    right: 20px;
}

.CarouselDots {
    font-size: 8pt;
    position: absolute;
    bottom: 5%;
    width: 100%;
    text-align: center;
}

.CarouselDot {
    display: inline-block;
    margin: 0 3px;
    cursor: pointer;
}


@media only screen and (max-width: 1279px) {

    .CarouselLeftArrow, .CarouselRightArrow {
        font-size: 15pt;
        line-height: 20px;
    }
    .CarouselElementTitle {        
        font-size: 14pt;   
    }
}