.Finalize {
    padding-top: 150px;
}

.FinalizeLogo {
    width: 400px;
    height: 402px;
    background-image: url("/overviewsquare.webp");
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto;
}

.FinalizeText {
    margin-top: 20px;
    text-align: center;
}

.Finalize .Error {
    margin-top: 5px;
    color: red;
    text-align: center;
    font-size: 9pt;
}


.FinalizeForm {
	width: 100%;
	font-family: 'Lato', sans-serif;
}

.FinalizeFormTitle {
	font-family: 'Lato', sans-serif;
	font-size: 20pt;
	text-align: center;
	width: 100%;
	line-height: 100px;
}

.FormFieldFixed {
    font-family: 'Roboto', sans-serif;
	border: 1px solid #aaa;
	height: 50px;
	width: 100%;
	padding: 20px;	
	border-radius: 5px;
	box-shadow: 0px 0px 10px #eee;
    font-style: italic;
}

.FinalizeButton {
	margin-top: 40px;
	width: 100%;	
}

.FinalizeButton .Button {
	margin: 0 auto;
}

.FinalizeButton .Error {
	color: red;
	font-size: 9pt;
	padding: 3px 0;
}