.Verify {
    padding-top: 150px;
}

.VerifyLogo {
    width: 400px;
    height: 402px;
    background-image: url("/overviewsquare.webp");
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto;
}

.VerifyText {
    margin-top: 20px;
    text-align: center;
}

.Verify .Error {
    margin-top: 5px;
    color: red;
    text-align: center;
    font-size: 9pt;
}

.VerifySignIn {
    text-align: center;
    padding: 10px;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.3);
    width: 150px;
    margin: 15px auto 0 auto;
    transition: all 0.3s;
    cursor: pointer;
}

.VerifySignIn a {
    transition: all 0.3s;
}

.VerifySignIn:hover {
    color: white;
    background-color: orange;
}

.VerifySignIn:hover a {
    color: white;
}
