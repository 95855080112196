.Contact {
	width: 940px;
	height: 100%;
    margin: 30px auto 0 auto;
}

.Contact .DashboardComponentSection {
	width: 100%;
}

.ContactForm {
	width: 100%;
}

.ContactForm .Button {
    margin: 20px auto 0 auto;
}

.ContactField {
	position: relative;
	line-height: 35px;
	margin: 0 0 5px 0;
    box-shadow: 2px 2px 2px #eee;
    border: 1px solid #ddd;
}

.ContactField:last-child {
	border: none;
}

.ContactMessage {
	height: 300px;
}

.ContactLabel {
	font-family: 'Lato', sans-serif;
	box-shadow: 1px 2px 3px 0px rgba(0,0,0,0.4);
	z-index: 1;
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 149px;
	height: 100%;
	font-size: 9pt;
	background-color: #666;
	color: #eee;
	padding: 0 2px 0 10px;
	letter-spacing: 1px;
	-webkit-user-select: none;  /* Chrome all / Safari all */
	-moz-user-select: none;     /* Firefox all */
	-ms-user-select: none;      /* IE 10+ */
	user-select: none;          /* Likely future */
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
}

.ContactMessage .ContactLabel {
	width: 149px;
	height: 299px;
}

.ContactInput {
	position: relative;
	display: inline-block;
	width: calc(100% - 150px);
	height: 100%;
	font-size: 9pt;
}

.ContactInput input {
	height: 100%;
	width: 100%;
	padding: 10px;
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	border: 0;
}

.ContactInput textarea {
	height: 299px;
	width: 100%;
	padding: 10px;
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	border: 0;
    resize: none;
    font-family: 'Roboto', sans-serif;
}

.Sending {
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: rgba(255, 255, 255, 0.3);
}