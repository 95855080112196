body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

* {
	margin: 0;
	padding: 0;
	position: relative;
	box-sizing: border-box;
	font-family: sans-serif;
}

*:focus {
	outline: none;
}

::-webkit-scrollbar {
    width: 4px;
    margin-right: 4px;
    padding-right: 3px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

::-webkit-scrollbar-thumb {
    background-color: #333;
}

::-webkit-scrollbar-track {
    background-color: #666;
}

html, body {
	width: 100%;
	height: 100%;	
}

body {
	overflow-y: scroll;
}

a {
	text-decoration: none;
	color: black;
}

select {
	padding: 10px;
	border-radius: 5px;
	width: 90%;
	margin-left: 5%;
	text-align: center;
	cursor: pointer;
	color: #888;
	font-weight: 400;
	font-size: 10pt;
	letter-spacing: 1px;
	transition: 0.3s all;
	box-shadow: 0px 0px 2px #9E5800;
	background-color: orange;
	color: white;
	text-shadow: 0px 0px 1px #9E5800;
}

.Form {
	width: 400px;
	margin: 0 auto;
}

.FormField {
	margin: 10px 0;
	font-family: 'Roboto', sans-serif;
}

.FormFieldLabel {
	text-align: left;
	width: 100%;
	margin: 3px 0;
	font-family: 'Roboto', sans-serif;
	font-size: 10pt;
}

.FormField p {
	font-family: 'Roboto', sans-serif;
	font-size: 8pt;
	padding: 5px 0;
}

.FormField .Error {
	font-family: 'Roboto', sans-serif;
	position: absolute;
	line-height: 50px;
	top: 0;
	left: 101%;
	color: red;
	font-size: 8pt;
	width: 200px;
	text-align: left;
}

input {
	font-family: 'Roboto', sans-serif;
	border: 1px solid #aaa;
	height: 50px;
	width: 100%;
	padding: 20px;	
	border-radius: 5px;
	box-shadow: 0px 0px 10px #eee;
}

.Button {
	padding: 10px;
	border-radius: 5px;
	width: 150px;
	text-align: center;
	cursor: pointer;
	color: #888;
	font-weight: 400;
	font-size: 10pt;
	letter-spacing: 1px;
	transition: 0.3s all;
	box-shadow: 0px 0px 2px #9E5800;
	background-color: orange;
	color: white;
	text-shadow: 0px 0px 1px #9E5800;
}

.ButtonContainer {
	width: 100%;
	text-align: center;
	display: block;
	margin: 5px 0;
}

.ButtonContainer .Button {
	margin: 0 auto;
}

.ButtonConfirmation {
	display: inline-block;
	text-transform: uppercase;
	margin-left: 5px;
	font-size: 9pt;
	vertical-align: middle;
	background-color: rgba(255, 255, 255, 0.7);
	padding: 0 20px;
}

.confirmation-enter {
	opacity: 0.01;
}
  
.confirmation-enter.confirmation-enter-active {
	opacity: 1;
	transition: opacity 300ms ease-in;
}
  
.confirmation-leave {
	opacity: 1;
}
  
.confirmation-leave.confirmation-leave-active {
	opacity: 0.01;
	transition: opacity 300ms ease-in;
}

.Inactive {
	background-color: #ccc;
	box-shadow: 0px 0px 2px #bbb;
	pointer-events: none;
}

.Button:hover {
	color: #eee;
}

.Button:active {
	color: #aaa;
}

.Message {
	color: red;
	font-size: 8pt;
	margin-top: 10px;
}

.Clear {
	clear: both;
}

#root {
	width: 100%;
	height: 100%;
}

.Wrapper {
	width: 100%;
	min-height: 100%;
	background-color: #fff;
}

.Tooltip {
	line-height: 20px;
	max-width: 300px;
	text-align: left;
	font-family: 'Roboto', sans-serif;
}

.fa-bandcamp {
	color: teal;
}

.fa-spotify {
	color: #84bd00;
}

.fa-amazon {
	color: #ff9900;
}

.fa-google {
	color: #4885ed;
}

.fa-soundcloud {
	color: #ff7700;
}

.fa-youtube {
	color: #ff0000;
}

.fa-facebook, .fa-facebook-f {
	color:#3b5998;
}

.fa-twitter {
	color: #00acee;
}

.fa-instagram {
	color: #C13584;
}

.CatalogUserSocial span[class^="fa"] {
	color: #eee;
	transition: color 0.3s;
}

.CatalogUserSocial span[class^="fa"]:hover {
	color: darkorange;
}
.Verify {
    padding-top: 150px;
}

.VerifyLogo {
    width: 400px;
    height: 402px;
    background-image: url("/overviewsquare.webp");
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto;
}

.VerifyText {
    margin-top: 20px;
    text-align: center;
}

.Verify .Error {
    margin-top: 5px;
    color: red;
    text-align: center;
    font-size: 9pt;
}

.VerifySignIn {
    text-align: center;
    padding: 10px;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.3);
    width: 150px;
    margin: 15px auto 0 auto;
    transition: all 0.3s;
    cursor: pointer;
}

.VerifySignIn a {
    transition: all 0.3s;
}

.VerifySignIn:hover {
    color: white;
    background-color: orange;
}

.VerifySignIn:hover a {
    color: white;
}

.Finalize {
    padding-top: 150px;
}

.FinalizeLogo {
    width: 400px;
    height: 402px;
    background-image: url("/overviewsquare.webp");
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto;
}

.FinalizeText {
    margin-top: 20px;
    text-align: center;
}

.Finalize .Error {
    margin-top: 5px;
    color: red;
    text-align: center;
    font-size: 9pt;
}


.FinalizeForm {
	width: 100%;
	font-family: 'Lato', sans-serif;
}

.FinalizeFormTitle {
	font-family: 'Lato', sans-serif;
	font-size: 20pt;
	text-align: center;
	width: 100%;
	line-height: 100px;
}

.FormFieldFixed {
    font-family: 'Roboto', sans-serif;
	border: 1px solid #aaa;
	height: 50px;
	width: 100%;
	padding: 20px;	
	border-radius: 5px;
	box-shadow: 0px 0px 10px #eee;
    font-style: italic;
}

.FinalizeButton {
	margin-top: 40px;
	width: 100%;	
}

.FinalizeButton .Button {
	margin: 0 auto;
}

.FinalizeButton .Error {
	color: red;
	font-size: 9pt;
	padding: 3px 0;
}
.Login {
	width: 600px;
	height: 450px;
	text-align: center;
}

.Login .Button {
	margin: 0 auto;
}

.LoginTitle {
	font-family: 'Lato', sans-serif;
	font-style: italic;
	font-size: 15pt;
	text-transform: uppercase;
	text-align: right;
	padding: 40px;
	background-color: #333;
	color: #eee;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	background-clip: text;	
}

.LoginRightTitle {
	font-family: 'Lato', sans-serif;
	font-size: 20pt;
	text-align: center;
	width: 100%;
	line-height: 100px;
}


.LoginRegisterLink, .PasswordForgottenLink {
	font-size: 8pt;	
	color: orange;
	cursor: pointer;
}

.LoginRegisterLink:hover, .PasswordForgottenLink:hover {
	text-decoration: underline;
}

.HiddenSubmit {
	display: none;
}

@media only screen and (max-width: 1279px) {
	.Login {
		width: 90%;
		min-width: 0px;
		height: auto;
		padding-bottom: 10px;
	}

	.LoginRightTitle {
		font-size: 15pt;
		line-height: 60px;
	}
}
.Overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.8);
	transition: all 0.3s;
    z-index: 10000;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.Window, .Popup {
	background-color: #fafafa;
	border: 1px solid #333;	
	border-radius: 3px;
    box-shadow: 2px 2px 5px 0px #aaa; 
}

.BugReport {
	width: 50%;
	min-width: 400px;
	padding-bottom: 20px;
	text-align: center;
}

.BugReport .Button {
	margin: 0 auto;
}

.WindowTitle {
	font-family: 'Lato', sans-serif;
	font-style: italic;
	font-size: 15pt;
	text-transform: uppercase;
	text-align: center;
	padding: 40px;
	background-color: #333;
	color: #222;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	background-clip: text;	
}

.BugForm {
	width: 70%;
	margin: 0 auto;
}

.BugForm .FormFieldLabel {
	text-align: center;
	font-size: 13pt;
}

.BugDescription {
	font-family: 'Roboto', sans-serif;
	height: 300px;
	width: 100%;
	padding: 10px;
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	border: 1px solid #888;
	resize: none;
}

.Popup {
	max-width: 400px;
	padding: 20px;
}

.WindowTopBar, .PopupTopBar {
	height: 30px;
	width: 100%;
	color: white;
	background-color: #333;
}

.WindowClose {
	position: absolute;
	top: 7px;
	right: 7px;
	cursor: pointer;
	transition: color 0.3s;
}

.WindowClose:hover {
	color: orange;
}

.Loading {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	
}

.LoadingInfo {
	position: absolute;
	top: 45%;
	left: 150px;
	width: calc(100% - 150px);
	text-align: center;
	font-family: 'Lato', sans-serif;
	text-transform: uppercase;
	font-size: 9pt;
	color: orange;
}

.LoadingInfo img {
	width: 50px;
	height: 50px;
	margin: 20px;
}

.Forgot {
	width: 600px;
	min-width: 600px;
	height: 400px;
	text-align: center;
}

.Forgot .Button {
	margin: 0 auto;
}


.ForgotRightTitle {
	font-family: 'Lato', sans-serif;
	font-size: 20pt;
	text-align: center;
	width: 100%;
	line-height: 100px;
}

.Forgot .Message {
	color: black;
	font-size: 11pt;
	padding: 10px;
}

.RegisterLink, .SignInLink {
	padding: 5px 0;
	font-size: 8pt;
	color: orange;
	cursor: pointer;
}

.RegisterLink:hover, .SignInLink:hover {
	text-decoration: underline;	
}

@media only screen and (max-width: 1279px) {
	.Forgot {
		width: 90%;
		min-width: 0px;
		height: auto;
		padding-bottom: 10px;
	}

	
	.ForgotRightTitle {
		font-size: 15pt;		
		line-height: 60px;
	}
}

.Register {
	width: 700px;
	height: 700px;
	text-align: center;
}

.Cancel {
	width: 700px;
	height: 400px;
	text-align: center;
}

.Cancel .Button {
	margin: 20px auto;
}

.RegisterForm {
	width: 100%;
	font-family: 'Lato', sans-serif;
}

.RegisterFormTitle {
	font-family: 'Lato', sans-serif;
	font-size: 20pt;
	text-align: center;
	width: 100%;
	line-height: 100px;
}

.RegisterButton {
	margin-top: 40px;
	width: 100%;	
}

.RegisterButton .Button {
	margin: 0 auto;
	display: inline-block;
}

.RegisterButton .Error {
	color: red;
	font-size: 9pt;
	padding: 3px 0;
}

.FormFieldLabelBig {
	font-family: 'Lato', sans-serif;
	text-align: center;
	padding: 10px;
}

.FormFieldOption, .FormFieldOptionPremium {
	background-color: #fff;
	font-family: 'Lato', sans-serif;
	display: inline-block;
	width: 150px;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
	padding: 10px;
	margin: 0 10px;
	cursor: pointer;
	border: 2px solid rgba(0,0,0,0);
}

.FormFieldOptionPremium {}

.FormFieldOptionSelected {
	border: 2px solid orange;
}

.RegisterVerify {
	width: 400px;
	margin: 40px auto 0 auto;
}

.RegisterVerifyLogo {
    width: 400px;
    height: 402px;
    background-image: url("/overviewsquare.webp");
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto;
}

.RegisterVerifyText {
	font-family: 'Lato', sans-serif;
	text-align: justify;
	padding: 20px;
	line-height: 25px;
}

.RegisterVerifyPrice {
	font-size: 20pt;
	padding-bottom: 30px;
	margin-bottom: 20px;
	border-bottom: 1px dotted #333;
}

.CarouselInner {
    height: 100%;
    width: 100%;
    margin: 0;
}

.CarouselElements {
    overflow: hidden;   
    width: 100%;
    height: 87%;
    border-radius: 7px;
    background-image: linear-gradient(#ffe4c7, #ffffff);
}

.CarouselElement {   
    text-align: center;   
    height: 100%;
    width: 100%;    
}

.CarouselElementImage {
    height: 85%;
    padding: 20px 0;
    
}

.CarouselElementImage video {
    height: 100%;
    width: auto;
}

.CarouselElementTitle {
    color: #eee;
    font-family: 'Lato', sans-serif;
    font-size: 25pt;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    padding: 17px 0;
    height: 15%;
    background-color: #222;
}

.CarouselLeftArrow, .CarouselRightArrow {
    position: absolute;
    font-size: 30pt;
    cursor: pointer;
    color: #aaa;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
    transition: color 0.3s;
    bottom: 14%;
    height: 50px;
    z-index: 100;
}

.CarouselLeftArrow:hover, .CarouselRightArrow:hover {
    color: orange;
}

.CarouselLeftArrow {    
    left: 20px;
}

.CarouselRightArrow {
    right: 20px;
}

.CarouselDots {
    font-size: 8pt;
    position: absolute;
    bottom: 5%;
    width: 100%;
    text-align: center;
}

.CarouselDot {
    display: inline-block;
    margin: 0 3px;
    cursor: pointer;
}


@media only screen and (max-width: 1279px) {

    .CarouselLeftArrow, .CarouselRightArrow {
        font-size: 15pt;
        line-height: 20px;
    }
    .CarouselElementTitle {        
        font-size: 14pt;   
    }
}
.Landing {
	width: 100%;
	height: 100%;
	color: #333;	
	background-color: #fafafa;
}

.LandingPage {
	width: 100%;
	height: 100vh;
	padding-top: 65px;
	padding-bottom: 90px;
}

.LandingLeftMenu {
	margin-left: 150px;
	height: 65px;
	float: left;
}

.LandingMenu {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 65px;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ddd;	
	font-size: 10pt;
	z-index: 3;	
}

.LandingLeftMenuItem {
	font-family: 'Lato', sans-serif;
	display: inline-block;
	text-transform: uppercase;
	cursor: pointer;
	margin-right: 40px;
	line-height: 65px;
	transition: color 0.3s;
}

.Selected {
	color: darkorange;
}


.LandingRightMenu {
	margin-right: 150px;
	height: 65px;
	float: right;
}

.LandingRightMenuItem {
	font-family: 'Lato', sans-serif;
	display: inline-block;
	text-transform: uppercase;
	cursor: pointer;
	margin-left: 40px;
	line-height: 65px;
	transition: color 0.3s;
}

.LandingLeftMenuItem:hover, .LandingRightMenuItem:hover {
	color: orange;
}

.OrangeMenuItem {
	color: orange;
}

.OrangeMenuItem:hover {
	color: darkorange;
}

.LandingPageBottom {
	cursor: pointer;
	text-align: center;	
	font-family: 'Roboto', sans-serif;	
	color: #eee;
	transition: color 0.3s;
	background-color: #222;
	box-shadow: 0px -4px 8px rgba(0,0,0,0.3), 0px 4px 8px rgba(0,0,0,0.3);
	border-top: 1px solid black;
	padding: 15px 0 10px 0;
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 90px;
	z-index: 3;
}

.LandingPageBottom:hover .LandingPageBottomChevron {
	color: orange;
}

.LandingPageBottom:hover .LandingPageBottomLinkText {
	color: #eee;
}

.LandingPageBottomLinkText {
	font-size: 9pt;
	margin-bottom: 10px;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.LandingPageBottomChevron {
	font-size: 25pt;
	transition: color 0.3s;
	text-shadow: 1px 1px 4px rgba(0,0,0,0.7), -1px -1px 3px rgba(0,0,0,0.2);
	color: #fff;
}

.LandingPageTitle {
	font-family: 'Lato', sans-serif;
	font-size: 30pt;
	font-style: italic;
	text-shadow: 0px 2px 3px rgba(0,0,0,0.6);
	position: absolute;	
	text-align: center;
	width: 300px;
	height: 80px;
	left: 0;
	top: calc(50% + 130px);
	-webkit-transform: rotate(270deg);
	        transform: rotate(270deg);
	-webkit-transform-origin: top left;
	        transform-origin: top left;
	background-color: #222;
	line-height: 80px;
	color: #eee;
	box-shadow: -2px 2px 2px rgba(0,0,0,0.2);
}

#Overview, #Features {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.LandingHeader {
	position: relative;
	width: 100%;
	height: 100%;
	background-image: url("/eg_banner.webp");
	background-size: cover;
	background-position: center right;
	box-shadow: -2px 2px 5px rgba(0,0,0,0.2);
	overflow: hidden;
}

.LandingHeader video {
	position: relative;
	min-width: 100%;
	min-height: 100%;
	float: right;
	overflow: hidden;
}
					
.LandingHeaderText {
	display: none;
	position: absolute;
	bottom: 90px;
	width: 100%;
	background: rgba(255,255,255,0.8);
	padding: 20px 0;
}

.LandingHeaderTextLeft {
	width: 100%;
	text-align: center;
	font-family: 'Roboto', sans-serif;
	font-style: italic;
	text-shadow: -2px 2px 8px rgba(0,0,0,0.2);
	
}

.LandingHeaderTextRight {
	display: none;
}

.LandingLogin .Form .Message {	
	color: green;
	font-size: 11pt;
}


.LandingOverviewCarousel {
	width: 500px;
	height: 565px;
	display: inline-block;
	vertical-align: top;
	margin-right: 100px;
}

.LandingOverviewImage {
	height: 500px;
	width: 503px;
	background-image: url("/overviewsquare.webp");
	display: inline-block;
	vertical-align: top;
	margin-top: -72px;
}

.Features {
	width: 1000px;
	text-transform: capitalize;
	font-family: 'Lato', sans-serif;
}

.FeatureHeaders {
	display: block;
	width: 100%;
}

.FeatureCategory {
	width: 300px;
	display: inline-block;
	text-align: center;
	color: green;
}

.FeatureHeaders .FeatureCategory {
	color: black;
	font-weight: bold;
}

.FeatureCategoryText {
	color: black;
}

.FeatureCategoryPrice {
	display: none;
}

.Feature {
	display: block;
	width: 100%;
	border-bottom: 1px solid black;
	padding: 3px 0;
}

.Feature:last-child {
	border-bottom: none;
}

.FeatureName {
	width: 400px;
	display: inline-block;
	vertical-align: middle;
	text-transform: none;
}

.FeatureTitle {
	font-size: 13pt;
	margin-bottom: 3px;
}

.FeatureDescription {
	text-transform: none;
	font-size: 10pt;
}

.FeatureTitle {
	color: #111;
	font-family: 'Lato', sans-serif;
	font-weight: bold;
}

.PricingPlans {
	width: 80%;
	max-width: 1100px;
	height: 100%;
	margin: auto;
	text-align: center;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.PricingPlanOuter {
	display: inline-block;
	vertical-align: top;
	width: 26%;
	margin: 0 2%;
	padding: 3px;
	border: 4px solid #fafafa;
}

.PricingPlanOuterChosen {
	border: 4px solid orange;
}

.PricingPlanInner {
	width: 100%;
	border: 1px solid #ccc;
}

.PricingPlanTitle {
	font-family: 'Lato', sans-serif;
	padding: 10px;
	text-transform: uppercase;
	background-color: orange;
	color: white;
}

.PricingPlanFeatures {
	font-family: 'Roboto', sans-serif;
}

.PricingPlanFeature {
	border-bottom: 1px solid #ddd;
}

.PricingPlanPrice {
	font-family: 'Lato', sans-serif;
	background-color: #333;
	color: white;
	padding: 10px;
}

.LandingOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.8);
	transition: all 0.3s;
	z-index: 4;
}


@media only screen and (max-width: 1279px) {

	.LandingPage {
		width: 100%;
		height: 100vh;
	}

	.LandingPageBottom {
		display: none;
	}
	
	.LandingRightMenuItem {
		font-family: 'Lato', sans-serif;
		display: inline-block;
		text-transform: uppercase;
		cursor: pointer;
		margin-right: 20px;
		line-height: 65px;
		transition: color 0.3s;
	}

	.LandingRightMenuItem:last-child {
		margin-right: 0;
	}


	.LandingMenu {
		background-color: rgba(255,165,0,0.9);
		color: #fafafa;
		height: 50px;
	}

	.LandingLeftMenu {
		display: none;
	}    
	
	.LandingRightMenu {
		margin-right: 25px;
		height: 50px;
	}

	.LandingRightMenuItem {
		margin-left: 0;
		line-height: 50px;
	}


	.LandingPageTitle {
		display: none;
	}

	.LandingHeader {
		background-image: url("/overviewsquare.webp");
		background-size: contain;
		background-repeat: no-repeat;
		box-shadow: none;
	}

	.LandingHeader video {
		display: none;
	}

	.LandingHeaderText {
		width: 100%;
		height: 100%;
	}

	.LandingHeaderTextLeft {
		display: none;
	}

	.LandingHeaderTextRight {
		width: 100%;
		height: 100%;
		display: block;
	}

	.LandingLogin .Form .Message {	
		color: green;
		font-size: 11pt;
	}

	.LandingLogin {
		display: none;
		padding-top: 20px;
	}

	.Form {
		width: 90%;
	}

	.LandingOverviewCarousel {
		padding-top: 50px;
		width: 90%;
		height: 400px;
		display: block;
		margin-right: 0;
	}

	.LandingOverviewImage {
		display: none;
	}

	
	.Features {
		width: 90%;
		text-transform: capitalize;
		font-family: 'Lato', sans-serif;
		padding-top: 50px;
	}

	.FeatureHeaders {
		display: block;
		width: 100%;
	}

	.FeatureCategory {
		width: 33%;
		display: inline-block;
		text-align: center;
		color: green;
	}

	.FeatureHeaders .FeatureCategory {
		color: black;
		font-weight: bold;
	}

	.FeatureCategoryName {
		font-size: 8pt;
	}

	.FeatureCategoryText {
		color: black;
		font-size: 8pt;
	}

	.FeatureCategoryPrice {
		display: none;
	}

	.Feature {
		display: block;
		width: 100%;
		border-bottom: 1px solid black;
		padding: 1px 0;
	}

	.Feature:last-child {
		border-bottom: none;
	}

	.FeatureName {
		width: 33%;
		display: inline-block;
		vertical-align: middle;
		text-transform: none;
	}

	.FeatureTitle {
		font-size: 9pt;
		margin-bottom: 3px;
	}

	.FeatureDescription {
		display: none;
	}

	.FeatureTitle {
		color: #111;
		font-family: 'Lato', sans-serif;
		font-weight: bold;
	}

	.LandingOverlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.8);
		transition: all 0.3s;
		z-index: 4;
	}

}

@media only screen and (max-width: 2000px) and (min-width: 1280px) {

	.Landing {
		min-width: 1280px;	
	}

	.LandingMenu {
		background-color: rgba(255,255,255,0.9);
	}

	.LandingLeftMenu {
		margin-left: 150px;
	}

	.LandingRightMenu {
		margin-right: 150px;
	}

	.LandingHeaderTextLeft {
		font-size: 30pt;
	}

	.LandingLogin {
		display: none;
		position: absolute;
		bottom: 50px;
		right: 40px;
		background-color: rgba(255,255,255,0.9);
		padding: 15px;
		box-shadow: 1px 2px 5px rgba(0,0,0,0.2);
		border-radius: 5px;
	}

	.LandingLogin .Form {
		font-family: 'Lato', sans-serif;
		font-size: 10pt;
	}

	.LandingLogin .FormField input {
		height: 35px;
		padding: 5px;
	}

	.LandingLogin .Form .Button {	
		margin-bottom: 3px;
	}
							
	.LandingHeaderRequestBeta {

	}
}

@media only screen and (min-width: 2001px) {

	.Landing {
		min-width: 1280px;	
	}

	.LandingMenu {
		background-color: rgba(255,255,255,0.9);
	}

	.LandingLeftMenu {
		margin-left: 150px;
	}

	.LandingRightMenu {
		margin-right: 150px;
	}

	.LandingHeaderTextLeft {
		font-size: 50pt;
	}

	.LandingLogin {
		display: none;
	}

	.LandingLogin .Form {
		font-family: 'Lato', sans-serif;
		font-size: 10pt;
	}

	.LandingLogin .FormField input {
	}

	.LandingLogin .Form .Button {	
		margin-bottom: 3px;
	}
							
	.LandingHeaderRequestBeta {

	}
}



.Dashboard {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	min-width: 1300px;
}

.DashboardComponent {
	position: absolute;
	top: 125px;
	left: 245px;	
	min-height: calc(100% - 125px);
	padding: 0;
}

.DashboardComponentHeader {
	position: fixed;
	top: 45px;
	left: 245px;
	height: 50px;
	font-family: 'Lato', sans-serif;
	line-height: 50px;		
	text-align: center;			
	z-index: 3;
}

.DashboardComponentTitle {
	display: inline-block;
	color: #eee;
	font-size: 15pt;
	text-transform: capitalize;
	font-weight: bold;
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.8);
	background-color: #666;	
	padding: 0 20px;
	vertical-align: top;
	margin: 0 10px 0 0;
}

.DashboardComponentTitle .fa {
	padding-right: 20px;
}

.DashboardComponentMenu {
	display: inline-block;
	vertical-align: top;
}

.DashboardComponentMenuItem {
	font-family: 'Lato', sans-serif;
	display: inline-block;
	cursor: pointer;
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.8);
	margin: 0 5px;
	padding: 0 10px;
	background-color: #444;
	color: #eee;
	font-size: 10pt;
	height: 40px;
	line-height: 40px;
	vertical-align: top;
	transition: color 0.3s, background-color 0.3s;
	-webkit-user-select: none;
	        user-select: none;
}

.DashboardComponentMenuItemInactive {
	font-family: 'Lato', sans-serif;
	display: inline-block;	
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.8);
	margin: 0 5px;
	padding: 0 10px;
	background-color: #777;
	color: #eee;
	font-size: 10pt;
	height: 40px;
	line-height: 40px;
	vertical-align: top;
	transition: color 0.3s, background-color 0.3s;
	-webkit-user-select: none;
	        user-select: none;
}

.Spaced {
	margin-left: 40px;
}

.Unsaved .fa {
	color: darkorange;
}

.DashboardComponentMenu .fa {
	margin-right: 5px;
}

.DashboardComponentMenuItem:hover {
	color: orange;
	background-color: #555;
}

.DashboardComponentMenuItem:active {
	background-color: #666;
}

.DashboardComponentSection {
	position: relative;
	display: inline-block;
	vertical-align: top;
	margin-right: 20px;
	margin-bottom: 20px;
}

.DashboardComponentSectionTitle {
	position: relative;
	font-size: 13pt;
	font-family: 'Lato', sans-serif;
	text-align: center;
	padding: 8px 10px;
	background-color: #444;
	color: #eee;
	margin-bottom: 7px;
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
	box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.4);
	z-index: 2;
}

.DashboardMenuTop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 45px;
	border-bottom: 1px solid #000;
	box-shadow: 0px 1px 3px 0px #444;
	background-color: #333;
	z-index: 5;
}

.DashboardMenuTopLogo {
	position: fixed;
	top: 5px;
	left: 0;
	line-height: 60px;
	font-size: 30pt;
	color: white;
	width: 65px;
	text-align: right;
	padding-right: 15px;
	text-shadow: 0px 2px 1px rgba(0,0,0,0.4),
             0px 4px 7px rgba(0,0,0,0.1),
             0px 9px 11px rgba(0,0,0,0.1);
	cursor: pointer;
	transition: color 0.3s, width 0.3s;	
	border-radius: 0 40px 40px 0;
	background-color: orange;
	box-shadow: 0px 1px 3px 0px #222;
	z-index: 6;
}

.DashboardMenuTopLogo:hover {
	color: #333;
	width: 80px;
}

.DashboardMenuTopItems {
	position: absolute;
	top: 0;
	right: 20px;
	z-index: 6;
}

.DashboardMenuTopItem {
	position: relative;
	line-height: 45px;
	display: inline-block;
	font-size: 16pt;
	min-width: 45px;
	text-align: center;
	text-shadow: 0px 2px 1px rgba(0,0,0,0.4),
             0px 4px 7px rgba(0,0,0,0.1),
             0px 9px 11px rgba(0,0,0,0.1);
	color: #fff;
	cursor: pointer;
	transition: color 0.3s;
}

.DashboardMenuTopProfileName {
	font-size: 11pt;
	display: inline-block;
	height: 45px;
	line-height: 45px;
	vertical-align: top;
}

.DashboardMenuTopItemHome {
	position: relative;
}

.DashboardMenuTopItemNotifications {
	position: relative;
	margin-right: 30px;
}

.DashboardMenuTopItem:hover {
	color: orange;
}

.DashboardMenuSide {
	position: fixed;
	top: 45px;
	left: 0;
	width: 215px;
	height: calc(100% - 45px);
	background-color: #333;
	border-right: 1px solid #111;
	box-shadow: 2px 2px 5px 0px #111;
	padding-top: 30px;
	z-index: 4;
}

.DashboardMenuTab {
	position: relative;
	margin-bottom: 10px;
}

.DashboardMenuTabTitle {
	position: relative;
	color: #eee;
	width: calc(100% + 5px);
	font-family: 'Roboto', sans-serif;
	text-transform: capitalize;
	font-size: 11pt;
	letter-spacing: 1px;
	padding: 0 15px;
	line-height: 40px;
	cursor: pointer;
	box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.4), 1px -2px 3px 0px rgba(0,0,0,0.3);
	background-color: #444;
	transition: background-color 0.3s, color 0.3s;
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
	z-index: 3;
}

.DashboardMenuTabTitle .fa {
	position: relative;
	font-size: 11pt;
	padding-right: 10px;
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 3px 4px rgba(0,0,0,0.5);
}

.DashboardMenuTabTitle:hover {
	background-color: #555;
	color: orange;
}

.DashboardMenuItem {	
	position: relative;
}

.DashboardMenuItemText {
	position: relative;
	font-family: 'Roboto', sans-serif;
	color: #eee;
	font-size: 10pt;
	padding-left: 25px;
	line-height: 30px;
	cursor: pointer;
	border-bottom: 1px solid #222;
	background-color: #333;
	transition: background-color 0.3s, color 0.3s;
	z-index: 2;
}

.DashboardMenuItemText .fa {
	padding-right: 8px;
	width: 25px;
	text-align: center;
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
}

.DashboardMenuItemText:hover {
	background-color: #555;
	color: orange;
}

.DashboardMenuItem .Tooltip {
}

.DashboardMenuBugReport {
	position: absolute;
	bottom: 10px;
	height: 30px;
	width: 100%;
	background-color: red;
}

.AccountMenu {
	position: absolute;
	right: -10px;
	top: 6px;
	width: 200px;
	background-color: rgba(0, 0, 0, 0);
	padding-top: 40px;
	z-index: -1;	
}

.AccountMenuItem {
	color: #eee;
	font-family: 'Lato', sans-serif;
	background-color: #333;
	font-size: 12pt;
	transition: color 0.3s, background-color 0.3s;
	text-align: left;
	padding: 0 10px;
	height: 50px;
	line-height: 50px;
	box-shadow: 0px 1px 3px 0px #444;
	border-bottom: 1px solid #444;
	text-shadow: 0px 1px 0px rgba(0,0,0,0.4),
			0px 1px 2px rgba(0,0,0,0.2),
			0px 2px 6px rgba(0,0,0,0.2);
}
.AccountMenuItem:hover {
	color: orange;
	background-color: #444;
}

.AccountMenuItem .fa {
	position: relative;
	font-size: 10pt;
	padding-left: 10px;
	width: 40px;
	height: 50px;
	line-height: 50px;
}

.Notifications {
	position: absolute;
	right: -30px;
	top: 6px;
	width: 300px;
	background-color: rgba(0, 0, 0, 0);
	padding-top: 40px;
	z-index: -1;
}

.NewNotificationsMarker {
	width: 8px;
	height: 8px;
	border-radius: 5px;
	background-color: orange;
	position: absolute;
	top: 8px;
	right: 8px;	
}

.Notification {
	color: #fff;
	font-family: 'Lato', sans-serif;
	background-color: #333;
	font-size: 10pt;
	line-height: 30px;
	transition: color 0.3s, background-color 0.3s;
	text-align: justify;
	padding: 15px;
	height: auto;
	box-shadow: 0px 1px 2px 0px #444;
	border-bottom: 2px solid #444;
	text-shadow: none;
	transition: all 0.3s;
}

.Notification:hover {
	background-color: #444;
}

.NewNotification {
	border-left: 2px solid orange;
}

.Validating {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	
}

.ValidatingInfo {
	position: absolute;
	top: 45%;
	left: 0;
	width: 100%;
	text-align: center;
	font-family: 'Lato', sans-serif;
	text-transform: uppercase;
	font-size: 9pt;
	color: orange;
}

.ValidatingInfo img {
	width: 50px;
	height: 50px;
	margin: 20px;
}

.DashboardComponentMenuInfo {
	display: inline-block;
	text-transform: uppercase;
	margin-left: 30px;
	font-size: 9pt;
	vertical-align: top;
	background-color: rgba(255, 255, 255, 0.7);
	padding: 0 20px;
}

.Saving {
	color: orange;
}

.Saved {
	color: #555;
}

.saving-enter {
	opacity: 0.01;
}
  
.saving-enter.saving-enter-active {
	opacity: 1;
	transition: opacity 300ms ease-in;
}
  
.saving-leave {
	opacity: 1;
}
  
.saving-leave.saving-leave-active {
	opacity: 0.01;
	transition: opacity 300ms ease-in;
}
.Reset {
    padding-top: 150px;
}

.ResetLogo {
    width: 400px;
    height: 402px;
    background-image: url("/overviewsquare.webp");
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto;
}

.Reset .Form p {
    font-size: 10pt;
    margin-bottom: 20px;
}


.FormField .Error {
	font-family: 'Roboto', sans-serif;
	position: absolute;
	line-height: 50px;
	top: 30px;
	left: 101%;
	color: red;
	font-size: 8pt;
	width: 200px;
	text-align: left;
}

.ResetButton {
    margin-top: 40px;
}

.Reset .Form .ResetButton .Button {
    margin: 0 auto;
}

.ResetSuccessMessage {
    margin: 30px auto;
    width: 400px;
    font-size: 10pt;
    text-align: center;
}

.ResetSuccessMessage p a {
    color: orange;
}

.ResetSuccessMessage p a:hover {
    text-decoration: underline;
}

.Change {
    width: 700px;
	height: 600px;
	text-align: center;
}

.DashboardLanding {
    width: 1100px;
	height: 100%;
	margin: 0 auto;
}

.DashboardLandingLatest {
    width: 550px;
    height: 100%;
    display: inline-block;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
}


.DashboardLandingLatestTitle {
    font-family: 'Lato', sans-serif;    
    background-color: #aaa;
    color: #fff;
    width: 100%;
    height: 150px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.8);
    cursor: pointer;
    transition: color 0.3s;
}

.DashboardLandingLatestTitle:hover {
    color: orange;
}

.DashboardLandingLatestTitleCover {
    width: 130px;
    height: 130px;
    position: absolute;
    left: 10px;
    top: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.8);
}

.DashboardLandingLatestRelease {
    position: absolute;
    top: 10px;
    right: 10px;
    text-align: right;
    text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
    width: calc(100% - 170px);
}

.DashboardLandingLatestReleaseAnnounce {
    font-size: 17pt;
}

.DashboardLandingLatestReleaseTitle {
    font-size: 20pt;
}

.DashboardLandingLatestReleaseArtist {
    font-size: 15pt;
}

.DashboardLandingLatestTitleCover img {
    height: 100%;
    width: 100%;
}

.DashboardLandingSchedule {
    display: inline-block;
    vertical-align: top;
    margin-left: 80px;
    width: 450px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
}

.DashboardLandingScheduleTitle {
    font-family: 'Lato', sans-serif;
    font-size: 30pt;
    text-align: center;
    background-color: darkorange;
    color: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.8);
    text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
    padding: 20px 0;
}

.DashboardLandingScheduleItem {
    width: 100%;
    margin: 10px 0;
    background-color: #eee;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
    padding: 10px;
    height: 100px;
    cursor: pointer;
    transition: box-shadow 0.3s;
}

.DashboardLandingScheduleItem:hover {
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.8);
}

.DashboardLandingScheduleItemDate {
    position: absolute;
    bottom: 35px;
    right: 10px;

}

.DashboardLandingScheduleItemRelease {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.DashboardLandingScheduleItemReleaseCover {
    width: 100px;
    height: 100px;
    background-color: rgba(255,255,255,0.5);
}

.DashboardLandingScheduleItemReleaseCover img {
    width: 100%;
    height: 100%;
}

.DashboardLandingScheduleItemReleaseTitle {
    position: absolute;
    top: 10px;
    left: 110px;
    font-size: 15pt;
}

.DashboardLandingScheduleItemReleaseArtist {
    position: absolute;
    top: 30px;
    left: 110px;
}

.DashboardLandingScheduleItemTask {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 18pt;
    font-family: 'Roboto', sans-serif;
}

.DashboardLandingLatestEmpty {
    font-size: 14pt;
    padding: 20px;
    text-align: left;
}

.DashboardLandingScheduleEmpty {
    font-size: 14pt;
    padding: 20px;
    text-align: center;
}

.DashboardLandingNewRelease {
    margin: 20px auto;
    padding: 10px 20px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.3);
    width: 370px;
    cursor: pointer;
    background-color: orange;
    color: #eee;
    border-radius: 5px;
    opacity: 0.8;
    transition: opacity 0.3s;
}

.DashboardLandingNewRelease:hover {
    opacity: 1;
}

.DashboardLandingNewReleaseLogo {
    font-size: 50pt;
    display: inline-block;
    width: 70px;
    vertical-align: middle;
}

.DashboardLandingNewReleaseText {
    font-size: 20pt;
    display: inline-block;    
}
.StatSection {
    width: 100%;
    position: relative;
}

.StatsVisits {
    width: 100%;
    padding: 0 10px;
    margin: 7px 0 0 0;
    background-color: #eee;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.8);
}

.StatsMap {
    width: 100%;
    padding: 0 10px;
    margin: 7px 0 0 0;
    background-color: #eee;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.8);
}

.StatsCounts {
    width: calc(50% - 7px);
    margin: 7px 6px 0 0;
    padding: 0 10px;
    background-color: #eee;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.8);
    display: inline-block;
}

.StatsMails {
    width: 50%;
    margin: 7px 0 0 0;
    padding: 0 10px;
    background-color: #eee;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.8);
    display: inline-block;
}

.ChartTitle {
    width: 100%;
    font-family: 'Lato', sans-serif;
    font-size: 18pt;
    text-align: left;
    padding: 10px 0px 5px;    
    color: #333;
    margin: 10px 0 5px 0;
}

.ChartSubTitle {
    width: 100%;
    font-family: 'Lato', sans-serif;
    font-size: 11pt;
    text-align: left;
    padding: 0px 0px 5px;
    color: #555;
    margin-bottom: 10px;
    border-bottom: 1px solid #aaa;
}

.StatsMapInner {

}
.Preview {
    width: 90%;
    max-width: 1200px;
    height: 90%;
}

.PreviewLeft {
    display: inline-block;
    vertical-align: top;
    height: calc(100% - 30px);
    width: 70%;
    box-shadow: 1px 0 3px 0px rgba(0, 0, 0, 0.3);
    z-index: 2;
    overflow-y: scroll;
}

.PreviewLeft iframe {
    height: 200%;
    min-height: 1500px;
    width: 100%;
    border: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.PreviewRight {
    display: inline-block;
    vertical-align: top;
    height: calc(100% - 30px);
    width: 30%;
    background-color: #fafafa;
    overflow-y: scroll;
}

.PreviewRightTitle {
    font-family: 'Lato', sans-serif;
    font-size: 14pt;
    letter-spacing: 2px;
    word-spacing: 8px;
    text-align: center;
    padding: 20px 0;
    background-color: #222;
    color: #eee;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
    margin-top: 10px;
    margin-bottom: 20px;
}

.PreviewRightBlock {
    margin: 10px 0;
}

.PreviewRightTestTitle {
    height: 40px;
    line-height: 40px;
    background-color: #555;
    color: #eee;
    font-size: 10pt;
    letter-spacing: 1px;
    text-align: center;
}

.PreviewRightTestInput {
    width: 90%;
    margin: 10px auto;
    height: 30px;
    line-height: 30px;
    font-size: 10pt;
}

.PreviewRightTestInput input {
    height: 30px;
    line-height: 30px;
    display: inline-block;
    width: 130px;
    padding: 5px;
    margin-left: 5px;
    font-size: 10pt;
}

.PreviewRightTestButton .Button {
    margin-left: 20px;
    display: inline-block;
    vertical-align: middle;
}

.PreviewRightSend {
    position: absolute;
    width: 100%;
    bottom: 30px;
}

.PreviewRightSendText {
    padding: 20px;
    text-align: justify;
    font-size: 10pt;
    font-family: 'Roboto', sans-serif;
}

.PreviewOption {
    width: 100%;
    margin: 15px 0;
}

.PreviewOptionLabel {
    text-align: center;
    font-size: 9pt;    
    background-color: #333;
    color: #eee;
    padding: 10px;
    width: 220px;
    margin: 0 auto 6px auto;
    font-family: 'Lato', sans-serif;
}

.PreviewOptionInput {
    width: 90%;
    margin: 0 auto;    
}

.CoverArtOption {
    text-align: center;
    padding: 10px 0;
    font-size: 10pt;
}

.CoverArtOption input {
    height: 13px;
    width: 13px;
    display: inline-block;
    vertical-align: middle;
}

.PreviewOptionCover {
    padding: 2px;
    cursor: pointer;
    border: 2px solid rgba(0,0,0,0);
    width: 220px;
}

.PreviewOptionColor {
    width: 220px;
}

.PreviewOptionColor input {
    height: auto !important;
    text-align: center;
}

.PreviewOptionCoverSelected {
    border: 2px solid darkorange;
}

.PreviewOptionInput img {
    width: 100%;
    height: auto;
}

.ShowDetails {
	position: absolute;
	top: 0;
	right: 0;
	width: 35px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	font-size: 12pt;
	color: #aaa;
	box-shadow: 2px 2px 3px rgba(0,0,0,0.05);
	cursor: pointer;
	z-index: 3;
	transition: color 0.3s;
}

.ShowDetails:hover {
	color: #eee;
}

.PreviewTrackList {
    width: 90%;
    margin: 10px auto 0 auto;
}

.PreviewTrack {
    border-bottom: 1px solid #999;
    background-color: #eee;
    font-family: 'Lato', sans-serif;
    font-size: 10pt;
    height: 25px;
    line-height: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
    padding: 0 10px;
}

.PreviewTrack:hover {
    background-color: #ccc;
}

.PreviewTrack:active {
    background-color: darkorange;
}

.Listen {
    background-color: orange;
}

.NewsletterParts, .PresskitParts {
    padding: 20px;
    position: relative;
}

.NewsletterPart, .PresskitPart {
    border: 1px solid #aaa;
    border-radius: 5px;
    box-shadow: 2px 2px 3px rgba(0,0,0,0.05);
    margin-bottom: 3px;
    background-color: rgba(255,255,255,0.9);
}

.NewsletterPartHandle, .PresskitPartHandle {
    vertical-align: top;
	display: inline-block;
	font-size: 12pt;	
	width: 27px;
	text-align: center;
	color: #ddd;
	cursor: move;
	-webkit-user-select: none;
	        user-select: none;
    border-right: 1px dashed #ddd;
    height: 40px;
    line-height: 40px;
}

.NewsletterPartToggle, .PresskitPartToggle {
    display: inline-block;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding-top: 4px;
    padding-left: 10px;
}

.NewsletterPartToggle input, .PresskitPartToggle input {
    width: 20px;
    height: 20px;
    line-height: 40px;
    cursor: pointer;
}

.NewsletterPartName, .PresskitPartName {
    display: inline-block;
    padding-left: 5px;
    font-size: 12pt;
    font-family: 'Lato', sans-serif;
    color: #444;
    font-weight: bold;
}

.NewsletterPartNameInactive, .PresskitPartNameInactive {
    color: #ddd;
}

.NewsletterPartShowDetails, .PresskitPartShowDetails {
    position: absolute;
    right: 10px;
    top: 12px;
    cursor: pointer;
}

.NewsletterPartDetails, .PresskitPartDetails {
    border-top: 1px solid #ddd;
}

.NewsletterPartDetail, .PresskitPartDetail {
    margin: 5px 10px;
    padding: 5px;
    box-shadow: 2px 2px 3px rgba(0,0,0,0.05);
    height: 40px;
}

.NewsletterPartDetailName, .PresskitPartDetailName {
    line-height: 30px;
    padding-left: 5px;
    font-size: 10pt;
    font-family: 'Lato', sans-serif;
    color: #444;
    width: 50%;
}

.NewsletterPartValue, .PresskitPartValue {
    position: absolute;
    right: 10px;
    top: 7px;
}

.NewsletterPartValue select, .PresskitPartValue select {
    padding: 3px;
    width: 120px;
    margin: 0;
    border-radius: 2px;
    box-shadow: none;
    text-shadow: none;
    letter-spacing: normal;
    background-color: #eee;
    color: #333;
}

.NewsletterPartCustomText, .PresskitPartCustomText {
    margin: 5px 10px;
    padding: 5px;
    box-shadow: 2px 2px 3px rgba(0,0,0,0.05);
}

.NewsletterPartCustomText textarea,.PresskitPartCustomText textarea {
    width: 100%;
    height: 100px;
    padding: 5px;
    border-color: #ddd;
}

.NewsletterPartImageUpload input, .PresskitPartImageUpload input {
    display: none;
}

.NewsletterAddParts, .PresskitAddParts {
    padding: 20px;
}

.NewsletterAddPart, .PresskitAddPart {
    border: 1px solid #aaa;
    border-radius: 5px;
    box-shadow: 2px 2px 3px rgba(0,0,0,0.05);
    margin-bottom: 3px;
    height: 40px;
    line-height: 40px;
    background-color: rgba(255,255,255,0.9);
    transition: background-color 0.3s;
    cursor: pointer;
}

.NewsletterAddPart:hover, .PresskitAddPart:hover {
    background-color: rgba(230,230,230,0.9);
}

.NewsletterAddPart:active, .PresskitAddPart:active {
    background-color: orange;
}

.NewsletterAddPartIcon, .PresskitAddPartIcon {
    vertical-align: top;
	display: inline-block;
	font-size: 12pt;	
	width: 27px;
	text-align: center;
	color: #333;	
	-webkit-user-select: none;	
	        user-select: none;
	border-right: 1px dashed #ddd;
}

.NewsletterAddPartName, .PresskitAddPartName {
    display: inline-block;
    padding-left: 15px;
    font-size: 10pt;
    font-family: 'Lato', sans-serif;
    color: #444;
    font-weight: bold;
}

.NewsletterPartDelete, .PresskitPartDelete {
    color: red;
    position: absolute;
    right: 50px;
    top: 12px;
    cursor: pointer;
}

.MinipageUploadImage input {
    display: none;
}

.MinipageImage {
	text-align: center;
	color: #fff;
    cursor: pointer;
    width: 220px;
    margin: 5px auto;
}

.MinipageImageChange {
    font-family: 'Lato', sans-serif;	
	line-height: 30px;
	text-align: center;		
	color: #333;
	font-size: 9pt;
	text-transform: uppercase;
	text-shadow: none;
	background-color: rgba(255,255,255,0.8);
    box-shadow: 0px 3px 2px 0px rgba(0,0,0,0.3);
    transition: all 0.3s;
}

.MinipageImageChange:hover {
	background-color: #ddd;
}


.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 10001 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  -webkit-user-select: none !important;
  user-select: none !important;
}
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
.AudioPlaylist {
	width: 100%;
    margin: 30px 0;
    font-family: 'Roboto', sans-serif;
}

.AudioPlaylistHiddenPlayer {
	display: none;
}

.AudioPlaylistInfoTitle {
	text-align: center;	
	mix-blend-mode: difference;
	color: white;
	z-index: 2;
}

.AudioPlaylistControls {
	height: 130px;	
}

.AudioPlaylistButton {
	display: inline-block;
	vertical-align: middle;
	margin-right: 2px;
	border: 1px solid #333;
	width: 50px;
	text-align: center;
	cursor: pointer;
	line-height: 48px;
	height: 50px;
	margin-bottom: 10px;
	font-size: 11pt;
}

.AudioPlaylistProgressBarContainer {
    width: 90%;
    margin: 0 5% 10px 5%;
	height: 30px;
	line-height: 30px;
	background-color: #111;
	margin-right: 2px;
	border: 1px solid #333;
	cursor: pointer;
	overflow: hidden;
	font-size: 7pt;
}

.AudioPlaylistProgressBar {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 0;
	background-color: #333;	
}

.AudioPlaylistTime {
    margin-left: 5%;
	float: left;
	height: 20px;
	line-height: 18px;
	width: 60px;
	text-align: center;
	background-color: #111;
	margin-right: 2px;
	border: 1px solid #333;
	pointer-events: none;
    font-size: 8pt;
    color: #eee;
}

.AudioPlaylistVolumeBarContainer {
    float: right;
    margin-right: 5%;
	vertical-align: middle;
	width: 75px;
	height: 20px;
	background-color: #111;
	
	border: 1px solid #333;
	cursor: pointer;
}

.AudioPlaylistVolumeBarContainer .fa {
	mix-blend-mode: difference;
	color: white;
    padding: 0 5px;
    font-size: 10pt;
}

.AudioPlaylistVolumeBar {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 0;
	background-color: #333;
}

.AudioPlaylistItems {
    margin-top: 5px;
    width: 90%;
    margin: 0 auto;
	border-top: 1px solid #555;
}

.AudioPlaylistItem {
	cursor: pointer;
	border-bottom: 1px solid #555;
	padding: 5px;
	transition: all 0.3s;
	font-size: 9pt;
	letter-spacing: 0.5pt;
	height: 30px;
	line-height: 23px;
	width: 100%;
	overflow: hidden;
}

.Unplayable {
	cursor: default;
	opacity: 0.5;
}

.AudioPlaylistItem .fa {
	float: left;
	color: #eee;
	height: 30px;
    line-height: 23px;
    padding-right: 10px;
}

.Unplayable .fa {
	color: transparent;
}

.AudioPlaylistItem:hover {
	background-color: #222;
}

.Unplayable:hover {
	background-color: transparent;
}

.AudioPlaylistItemTitle {
	margin-left: 3px;
	float: left;
	width: 85%;
	text-align: left;
}

.AudioPlaylistItemLength {
	float: right;
	text-align: right;
}

.AudioPlaylistItemPlaying {
	background-color: #222;
}
.MinipageWidget {
    width: 500px;
    height: 50px;
    overflow: hidden;
    padding: 20px 0 0 0;
}

.MinipageWidget .SubscribeInput {
    display: inline-block;
    width: 150px;
    margin-right: 10px;
    height: 30px;
    vertical-align: top;
}

.MinipageWidget .SubscribeInput .InputLabel {
    position: absolute;
    top: -12px;
    left: 0;
    font-size: 8pt;
    height: 10px;
    line-height: 10px;
}

.MinipageWidget .SubscribeInput input {
    border-radius: 0;
    padding: 2px 5px;
    height: 20px;
    font-size: 9pt;
    box-shadow: none;
}

.MinipageWidget .SubscribeButton {
    display: inline-block;
    width: 90px;
    background-color: #333;
    color: #eee;
    padding: 0 5px;
    height: 20px;
    text-align: center;
    vertical-align: top;
    font-size: 9pt;
    cursor: pointer;
    line-height: 20px;
    transition: all 0.3s;
    -webkit-user-select: none;
            user-select: none;
}

.MinipageWidget .SubscribeButton:hover {
    background-color: #888;
}

.MinipageWidget .SubscribeButton:active {
    background-color: #eee;
    color: #333;
}

@media only screen and (max-width: 500px) {
    .MinipageWidget {
        display: none;
    }
}
.MinipageOuter  {
    position: relative;
}

.MinipageBackground {
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    position: absolute;
    width: 100%;
	height: 100%;
}

.MinipageInner {
    min-width: 320px;
    max-width: 500px;
    min-height: 100vh;
    margin: 0 auto;
    font-family: 'Lato', sans-serif;
    text-align: center;
    background-color: #111111;
    color: #eee;
    box-shadow: 0 -3px 5px 2px rgba(0, 0, 0, 0.3);
    padding-bottom: 20px;
    -webkit-filter: blur(0);
            filter: blur(0);
}

.MinipageCover {
    width: 100%;
    height: auto;
    padding: 0;
}

.MinipageCover img {
    width: 100%;
    height: auto;
    margin: 0;
}

.MinipageTitle {
    top: -4px;
    padding: 20px 0;   
    border-bottom: 1px solid #333;
    background-color: darkorange;
}

.MinipageTitleTitle {
    font-size: 20pt;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}

.MinipageTitleArtists {
    font-size: 16pt;
    font-family: 'Lato', sans-serif;
}

.MinipageLink {
    font-size: 9pt;
    min-height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #333;
    transition: background-color 0.3s;
    cursor: pointer;
}

.MinipageLink:hover {
    background-color: #222;
}

.MinipageLinkLeft {
    display: inline-block;
    width: 65%;
    text-align: left;
    padding-left: 20px;
    vertical-align: middle;
    font-size: 10pt;
    font-family: 'Lato', sans-serif;  
}

.MinipageLinkLeft img {
    top: 7px;
    height: 30px;
}

.MinipageLinkLeft .fab, .MinipageLinkLeft .fa {    
    padding-right: 15px;
}

.MinipageLinkRight {
    display: inline-block;
    width: 35%;
    text-align: right;
    padding-right: 20px;
    vertical-align: middle;
}

.MinipageDescription {
    font-family: 'Lato', sans-serif;
    font-size: 10pt;
    text-align: justify;
    width: 90%;
    margin: 0 auto;
    padding: 10px 0;
}

.MinipageTags {
    font-family: 'Lato', sans-serif;
    font-size: 10pt;
    color: #999;
    width: 90%;
    margin: 20px auto 0 auto;
}

.MinipageReleaseDate {
    font-family: 'Lato', sans-serif;
    font-size: 10pt;
    width: 90%;
    margin: 20px auto 0 auto;
}

.MinipageCatalogNumber {
    font-size: 10pt;
    color: #999;
    margin: 20px 0;
}

.MinipageEG {
    font-family: 'Lato', sans-serif;
    font-size: 9pt;
    color: #eee;
    margin: 20px 0 0 0;
}

.MinipageEG a {
    color: #eee;
}

.MinipageEG a:hover {
    color: #999;
}
.EditRelease {
	width: 940px;
	height: 100%;
	margin: 0 auto;
}

.EditReleaseCover {
	width: 450px;
	height: 450px;
	box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.3);
	background-color: #eee;
}

.EditReleaseCover input {
	display: none;
}

.EditReleaseCoverImage {
	width: 100%;
	height: 100%;
	line-height: 450px;
	text-align: center;
	color: #fff;
	font-size: 240pt;
	cursor: pointer;
}

.EditReleaseCoverImageText {
	position: absolute;
	top: 0;
	width: 100%;
	height: 30px;
	text-align: center;
	font-size: 10pt;
	color: #333;
	font-family: 'Lato', sans-serif;
}

.EditReleaseCoverImage img {
	width: 100%;
	height: 100%;
}

.EditReleaseCoverImageChange {
	font-family: 'Lato', sans-serif;
	position: absolute;
	bottom: 25px;
	left: -3px;
	width: calc(100% + 6px);
	line-height: 30px;
	text-align: center;		
	color: #333;
	font-size: 9pt;
	text-transform: uppercase;
	text-shadow: none;
	background-color: rgba(255,255,255,0.8);
	box-shadow: 0px 3px 2px 0px rgba(0,0,0,0.3);
	z-index: 3;
}

.CoverProgressBar {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	pointer-events: none;
	z-index: 2;
}

.CoverProgress {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.9);
	transition: width 0.1s;
}

.ReactTags__tags {
	height: 100%;
	line-height: 35px;
}

.ReactTags__tagInput {
	display: inline-block;
}

.ReactTags__tagInputField {
	width: 80px;
}
.ReactTags__selected {
	padding-left: 5px;
}

.ReactTags__selected .ReactTags__tag {
	font-family: 'Lato', sans-serif;
	background-color: orange;
	font-size: 10pt;
	height: 25px;
	line-height: 25px;
	color: #eee;
	padding: 0 0 0 10px;
	margin: 0 2px;
	border-radius: 3px;
	display: inline-block;
	vertical-align: middle;
}

.ReactTags__selected .ReactTags__remove {
	color: #fff;
	margin-left: 8px;
	height: 25px;
	line-height: 25px;
	font-size: 12pt;
	cursor: pointer;
	border-left: 1px dashed #eee;
	padding: 0 5px;
	display: inline-block;
	background-color: rgba(0,0,0,0.1);
	border-radius: 0 3px 3px 0;
}

.ReactTags__suggestions {
	position: absolute;
	z-index: 5;	
	background: #eee;
	height: 205px;
	overflow: hidden;
	cursor: pointer;
	padding: 0;
}

.ReactTags__suggestions li {
	padding: 3px 10px;
	
	transition: background-color 0.2s;
	margin: 0 3px;
	box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.1);
}

.ReactTags__activeSuggestion {
	background-color: #fafafa;
}

.EditReleaseMeta {
	width: 450px;
}

.EditReleaseMetaField {
	position: relative;
	line-height: 35px;
	margin: 0 0 5px 0;
	box-shadow: 2px 2px 2px #eee;
}

.EditReleaseMetaField:last-child {
	border: none;
}

.EditReleaseMetaDescription {
	height: 100px;
}

.EditReleaseMetaLabel {
	font-family: 'Lato', sans-serif;
	box-shadow: 1px 2px 3px 0px rgba(0,0,0,0.4);
	z-index: 1;
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 149px;
	height: 100%;
	font-size: 9pt;
	background-color: #666;
	color: #eee;
	padding: 0 2px 0 10px;
	letter-spacing: 1px;
	-webkit-user-select: none;  /* Chrome all / Safari all */     /* Firefox all */      /* IE 10+ */
	user-select: none;          /* Likely future */
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
}

.EditReleaseMetaLabelText {
	display: inline-block;
	width: calc(100% - 20px);
	vertical-align: middle;
}

.EditReleaseMetaLabelInfo {
	display: inline-block;
	width: 20px;
	cursor: help;
	font-size: 11pt;
	vertical-align: middle;
}

.EditReleaseMetaDescription .EditReleaseMetaLabel {
	width: 149px;
	height: 99px;
	line-height: 99px;
}

.EditReleaseMetaFormInput {
	position: relative;
	display: inline-block;
	width: calc(100% - 150px);
	height: 100%;
	font-size: 9pt;
}

.EditReleaseMetaFormInput input {
	height: 100%;
	width: 100%;
	padding: 10px;
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	border: 0;
}

.EditReleaseMetaFormInput textarea {
	font-family: 'Roboto', sans-serif;
	height: 99px;
	width: 100%;
	padding: 10px;
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	border: 0;
	resize: none;
}

.EditReleaseLinks {
	width: 920px;
}

.EditReleaseLinksList {
	width: 100%;
	margin-bottom: 20px;
}

.EditReleaseLinkHeaders {
	width: 100%;
	background-color: #666;
	box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.6);
	margin: 0 0 10px 0;
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
			 0px 2px 6px rgba(0,0,0,0.5);
	color: #eee;
	font-family: 'Lato', sans-serif;
	font-size: 9pt;
	height: 30px; 
	line-height: 30px;
}

.EditReleaseLinkHeader {
	display: inline-block;
	vertical-align: top;
	text-transform: uppercase;
	padding: 0 10px;
	text-align: center;
}

.EditReleaseLinkHandle {
	width: 35px;
	display: inline-block;
	cursor: move;
	-webkit-user-select: none;
	        user-select: none;
	color: #999;
	text-align: center;
	line-height: 35px;
}

.EditReleaseLinkHeaderPlatform {
	width: 164px;
}

.EditReleaseLinkHeaderURL {
	width: calc(100% - 364px);
}

.EditReleaseLinkHeaderAction {
	width: 100px;
}

.EditReleaseLinkHeaderTest {
	width: 100px;
}

.EditReleaseLinksList {
	width: 100%;
}

.EditReleaseLink {	
	height: 35px;
	line-height: 35px;
	margin: 0 0 5px 0;
	box-shadow: 2px 2px 2px #eee;
}

.LinkRemove {
	position: absolute;
	top: 0;
	right: -45px;
	width: 35px;
	line-height: 35px;
	height: 35px;
	text-align: center;
	background-color: #fafafa;
	font-size: 12pt;
	color: red;
	box-shadow: 2px 2px 3px rgba(0,0,0,0.05);
	cursor: pointer;
	transition: background-color 0.2s;
}

.LinkRemove:hover {
	background-color: #eee;
}

.EditReleaseLinkPlatform {
	font-family: 'Lato', sans-serif;
	box-shadow: 1px 2px 3px 0px rgba(0,0,0,0.4);
	z-index: 1;
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 164px;
	height: 100%;
	font-size: 10pt;
	background-color: #666;
	color: #eee;
	padding: 0;
	letter-spacing: 1px;
	-webkit-user-select: none;  /* Chrome all / Safari all */     /* Firefox all */      /* IE 10+ */
	user-select: none;          /* Likely future */
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
}

.EditReleaseLinkPlatformIcon {
	display: inline-block;
	vertical-align: middle;
	width: 40px;
	text-align: center;
}

.EditReleaseLinkPlatformIcon img {
	max-width: 20px;
	max-height: 100%;
	width: auto;
	height: auto;
}

.EditReleaseLinkPlatformLabel {
	display: inline-block;
	vertical-align: top;
	width: calc(100% - 40px);
	height: 100%;
}

.EditReleaseLinkPlatform .fab, .EditReleaseLinkPlatform .fa {
	font-size: 12pt;
	padding-right: 5px;
	line-height: 35px;
	vertical-align: bottom;
	width: 30px;
}

.EditReleaseLinkUrl {
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: calc(100% - 399px);
	height: 100%;
	font-size: 9pt;
}

.EditReleaseLinkAction {
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 100px;
	height: 100%;
	font-size: 9pt;
}

.EditReleaseLinkTest {
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 100px;
	height: 100%;
	font-size: 9pt;
	text-align: center;
	background-color: #eee;
	font-family: 'Roboto', sans-serif;
	cursor: pointer;
	transition: background-color 0.3s;
}

.EditReleaseLinkTest a {
	transition: color 0.3s;
	color: #333;
}

.EditReleaseLinkTest:hover {
	background-color: #ddd;
}

.EditReleaseLinkTest:hover a {
	color: orange;
}

.EditReleaseLinkUrl input, .EditReleaseLinkAction input {
	height: 100%;
	width: 100%;
	padding: 10px;
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	border: 0;
	font-size: 9pt;
}

.EditReleaseLinkPlatform input {
	height: calc(100% + 1px);
	width: 100%;
	padding: 10px;
	margin: 0;
	top: -1px;
	border-radius: 0;
	box-shadow: none;
	border: 0;
	background-color: rgba(255, 255, 255, 0.3);
	font-size: 10pt;
	color: #eee;
	letter-spacing: 1px;
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
}

.EditReleaseLinkAction {
	border-left: 1px dotted #ccc;
}

.LoaderWrapper {
	width: 100%;
	padding: 10px;
}

.ReleaseAutoDetectLoader {
	border: 10px solid #fafafa;
	border-radius: 50%;
	border-top: 10px solid orange;
	width: 50px;
	height: 50px;
	left: calc(50% - 25px);
	-webkit-animation: spin 1s linear infinite; /* Safari */
	animation: spin 1s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
	0% { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
	0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
  }

.EditReleaseAutodetect {
	text-align: right;
	width: 100%;
	margin-bottom: 10px;
}

.EditReleaseAutodetect .Button {	
	display: inline-block;
}

.EditReleaseAutodetect .Inactive {
	pointer-events: all;
	cursor: not-allowed;
}

.EditReleaseAutodetectPoweredBy {
	font-size: 8pt;
	font-style: italic;
	padding: 5px;
}

.EditReleaseAddLink input {
	display: inline-block;
	width: 750px;
	margin-right: 20px;
	padding: 5px 20px;
	line-height: 30px;
	height: 35px;
	vertical-align: top;
}

.EditReleaseAddLink .Button {
	display: inline-block;
}

.EditReleaseTracks {
	width: 920px;
}

.EditReleaseTrackListHeaders {
	width: 100%;
	background-color: #666;
	box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.6);
	margin: 0 0 10px 0;
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
}

.EditReleaseTrackList {
	width: 100%;
}

.EditReleaseTrack {
	width: 100%;
	font-family: "Roboto", sans-serif;
	font-size: 8pt;
	margin: 5px 0;
	box-shadow: 2px 2px 3px rgba(0,0,0,0.05);
	background-color: #fafafa;
	line-height: 25px;
	border: 1px solid #eee;
	color: #333;
}

.TrackShowDetails {
	position: absolute;
	top: 0;
	right: 0;
	width: 35px;
	height: 27px;
	line-height: 27px;
	text-align: center;
	background-color: #fafafa;
	font-size: 12pt;
	color: #888;
	box-shadow: 2px 2px 3px rgba(0,0,0,0.05);
	cursor: pointer;
	z-index: 3;
	transition: color 0.3s;
}

.TrackShowDetails:hover {
	color: #333;
}

.TrackRemove {
	position: absolute;
	top: 0;
	right: -45px;
	width: 30px;
	height: 27px;
	line-height: 27px;
	text-align: center;
	background-color: #fafafa;
	font-size: 12pt;
	color: red;
	box-shadow: 2px 2px 3px rgba(0,0,0,0.05);
	cursor: pointer;
	transition: background-color 0.2s;
}

.TrackRemove:hover {
	background-color: #eee;
}

.EditReleaseTrackHandle {
	vertical-align: top;
	display: inline-block;
	font-size: 12pt;	
	width: 27px;
	text-align: center;
	color: #ddd;
	cursor: move;
	-webkit-user-select: none;
	        user-select: none;
	border-right: 1px dashed #ddd;
}

.EditReleaseTrackNumber {
	display: inline-block;
	width: 30px;
	text-align: center;	
	color: #888;
	pointer-events: none;
}

.EditReleaseTrackFilename {
	vertical-align: top;
	display: inline-block;
	width: 370px;
	text-align: left;
	color: #888;
	pointer-events: none;
	overflow: hidden;
}

.EditReleaseTrackName {
	display: inline-block;
	width: 227px;
	text-align: left;
	border-left: 1px dashed #ddd;
}

.EditReleaseTrackArtists {
	display: inline-block;
	width: 227px;
	text-align: left;
	border-left: 1px dashed #ddd;
	border-right: 1px dashed #ddd;
}

.EditReleaseTrackName input, .EditReleaseTrackArtists input {
	width: 100%;
	height: 25px;
	border: none;
	box-shadow: none;
	padding: 0 5px;
	line-height: 25px;
	font-size: 8pt;
}

.EditReleaseTrackDownloadable, .EditReleaseTrackListen, .EditReleaseTrackCover {
	display: inline-block;
	width: 65px;
	text-align: center;
	vertical-align: middle;
}

.EditReleaseTrackDownloadable input, .EditReleaseTrackListen input, .EditReleaseTrackCover input {
	width: 15px;
	height: 12px;
	padding: 0;
	margin: 0;
	line-height: 27px;
	text-shadow: none;
	box-shadow: none;
}

.EditReleaseTrackDetails {
	border-top: 1px dashed #eee;
	padding: 5px 20px 5px 28px;
}

.EditReleaseTrackDetail {
	margin: 4px 0;
	height: 30px;
}

.EditReleaseTrackDetailTitle {
	font-family: 'Lato', sans-serif;
	box-shadow: 1px 2px 3px 0px rgba(0,0,0,0.4);
	z-index: 1;
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 149px;
	height: 100%;
	font-size: 9pt;
	background-color: #666;
	color: #eee;
	padding: 0 2px 0 10px;
	letter-spacing: 1px;
	-webkit-user-select: none;  /* Chrome all / Safari all */     /* Firefox all */      /* IE 10+ */
	user-select: none;          /* Likely future */
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
}

.EditReleaseTrackDetailValue {
	display: inline-block;
	height: 100%;
}

.EditReleaseTrackDetailValue input[type="text"] {
	height: 100%;
	width: 248px;
	padding: 10px;
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	border: 0;
}

.EditReleaseTrackDetailValue input[type="checkbox"] {
	height: 20px;
	width: 20px;
	margin: 5px 10px;
	border-radius: 0;
	box-shadow: none;
	border: 0;
}

.Header {
	font-family: "Lato", sans-serif;
	color: #eee;
	font-size: 8pt;
	text-transform: uppercase;
	background-color: #666;
	border: none;
	line-height: 18px;
	vertical-align: middle;
}

#ButtonUploadTracks {
	position: absolute;
	top: 1px;
	right: -30px;
	z-index: 1;
	width: 150px;
	border-radius: 50px;
	text-align: right;
	font-size: 9pt;
}

#ButtonUploadTracks .fa {
	font-size: 11pt;
}

#ButtonUploadTracks:hover {
	right: -80px;
	color: #fff;
}

.EditReleaseTracksUpload {
	width: 100%;
}

.EditReleaseTracksUpload input {
	display: none;
}

.EditReleaseTracksUpload .Button {
	margin: 0 auto;
}

.EditReleaseTracksUploadPreview {

}

.ProgressBar {
	position: absolute;
	width: 100%;
	height: 27px;
	left: 0;
	top: 0;
	pointer-events: none;
	z-index: 2;
}

.Progress {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	transition: width 0.1s;
}
.Releases {
	width: 972px;
	height: 100%;
	margin: 0 auto;
}


.ReleasesEmpty {
    font-size: 14pt;
    padding: 20px 0;
    text-align: left;
}


.ReleasesNewRelease {
    margin: 20px 0;
    padding: 10px 20px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.3);
    width: 370px;
    cursor: pointer;
    background-color: orange;
    color: #eee;
    border-radius: 5px;
    opacity: 0.8;
    transition: opacity 0.3s;
}

.ReleasesNewRelease:hover {
    opacity: 1;
}

.ReleasesNewReleaseLogo {
    font-size: 50pt;
    display: inline-block;
    width: 70px;
    vertical-align: middle;
}

.ReleasesNewReleaseText {
    font-size: 20pt;
    display: inline-block;    
}

.Releases .DashboardComponentSection {
	width: 100%;
}

.ReleasesNoActive {
	padding: 20px 0;
	font-size: 14pt;
	text-align: center;
}

.ReleaseList {
	width: 972px;
}

.Release {
	display: inline-block;
	height: 230px;
	margin: 10px 10px 10px 0;
	width: calc(50% - 10px);
}

.Release:nth-child(even) {
	margin: 10px 0 10px 10px;
}

.WithStats {
	width: 100%;
	margin: 10px 0;
}

.WithStats:nth-child(even) {
	margin: 10px 0;
}

.ReleaseLeft {	
	min-width: 476px;
	display: inline-block;
	vertical-align: top;	
}

.WithStats .ReleaseLeft {
	margin-right: 10px;
}

.ReleaseRight {
	width: calc(50% - 10px);
	height: 100%;
	margin-left: 10px;
	display: inline-block;
	vertical-align: top;
}


.ReleaseCover {
	width: 150px;
	height: 150px;
	box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.3);
	display: inline-block;
	vertical-align: top;
	line-height: 150px;
	text-align: center;
	font-size: 100pt;
	background-color: #eee;
	color: #fff;
	cursor: pointer;
}

.ReleaseCover img {
	width: 100%;
	height: 100%;
}

.ReleaseInfo {
	display: inline-block;
	height: 150px;
	vertical-align: top;
	margin-left: 6px;
}

.ReleaseField {
	margin: 0 0 6px 0;
	box-shadow: 1px 2px 3px 0px rgba(0,0,0,0.4);
	background-color: #fff;
}

.ReleaseFieldTitle {
	font-family: 'Lato', sans-serif;
	box-shadow: 1px 2px 3px 0px rgba(0,0,0,0.4);
	z-index: 1;
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 100px;
	height: 100%;
	font-size: 9pt;
	background-color: #666;
	color: #eee;
	padding: 5px 10px;
	letter-spacing: 1px;
	-webkit-user-select: none;  /* Chrome all / Safari all */     /* Firefox all */      /* IE 10+ */
	user-select: none;          /* Likely future */
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
}

.ReleaseFieldValue {
	position: relative;
	display: inline-block;
	width: 220px;
	height: 100%;
	font-size: 9pt;
	padding: 5px 10px;
}

.ReleaseBottom {
	width: 100%;
	height: 30px;
	margin-top: 10px;
}

.ReleaseActions {
	width: 100%;
	height: 30px;
	margin-top: 10px;
}

.ReleaseAction {
	font-family: 'Lato', sans-serif;
	min-width: 30px;
	height: 30px;
	line-height: 30px;
	display: inline-block;
	text-align: center;
	vertical-align: middle;
	box-shadow: 1px 2px 3px 0px rgba(0,0,0,0.4);
	padding: 0 10px;
	margin-right: 5px;
	cursor: pointer;
	color: #333;
	transition: color 0.3s, background-color 0.3s;
	font-size: 10pt;
	text-transform: uppercase;
	background-color: #eee;
}

.ReleaseAction .fa {
	padding-right: 10px;
}

.ReleaseAction:hover {
	color: #666;
	background-color: #ddd;
}

.Delete {
	color: red;
}

.Delete:hover {
	color: darkred;
}

.ReleaseLinks {
	width: 100%;
	height: 30px;
	box-shadow: 1px 2px 3px 0px rgba(0,0,0,0.4);		
	padding: 0 10px;
}

.ReleaseLink {
	padding: 0 5px;
	line-height: 30px;	
	font-size: 14pt;
	display: inline-block;
	vertical-align: bottom;
}

.ReleaseLink a {
	color: #666;
	transition: color 0.3s;
}

.ReleaseLink a:hover {
	color: orange;
}

.ReleaseStats {
	width: 100%;
	height: 100%;
	box-shadow: 1px 2px 3px 0px rgba(0,0,0,0.4);
}

.ReleaseStatsEmpty {
	text-align: center;
	line-height: 230px;
	font-size: 100pt;
	color: #eee;
}

.ReleaseStatsText {
	font-family: 'Lato', sans-serif;
	text-transform: uppercase;
	font-size: 9pt;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	line-height: 190px;
}
.Subscribers {
	width: 972px;
	height: 100%;
	margin: 0 auto;
}

.SubscriberTable {
	width: 972px;
	margin-bottom: 10px;
}

.ReactTable {
	width: 100%;
	font-size: 9pt;
	font-family: "Lato", sans-serif !important;
}

.-header {
	text-transform: uppercase;
	font-size: 8pt;
}

.-pageJump input {
	height: auto;
}

.-pageSizeOptions {
	display: inline-block;
}

.SubscriberListDelete {
	text-align: center;
	color: red;
	cursor: pointer;
}

.SubscribersExtra {
	width: 100%;
}

.SubscribersExtra input#presskit, .SubscribersExtra input#newsletter {
	display: none;
}

.SubscriberAdd {
	display: inline-block;
}

.SubscriberRemoveAll {
	position: absolute;
	bottom: -60px;
	background-color: red;
	color: white;
	padding: 10px;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
	box-shadow: 0px 0px 2px #9E5800;
	border-radius: 5px;
	cursor: pointer;
	transition: all 0.3s;
}

.SubscriberRemoveAll:hover {
	background-color: darkred;
}

.SubscriberImportHelp {
	position: absolute;
	top: 15px;
	right: -50px;
	font-size: 20pt;
	cursor: pointer;
	color: orange;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.3);
}

.SubscriberImportHelp:hover {	
	text-shadow: 2px 1px 2px rgba(0,0,0,0.3);
}

.SubscriberAdd .Button {
	display: inline-block;
	width: 75px;
	height: 25px;
	line-height: 25px;
	padding: 0;
	vertical-align: bottom;
	border-radius: 2px;
	margin-left: 4px;
	top: 1px;
}

.SubscriberAddTitle {
	width: 100%;
	background-color: #999;
	color: #eee;
	font-size: 9pt;
	font-family: "Lato", sans-serif !important;
	height: 30px;
	line-height: 30px;
	text-align: center;
	margin-bottom: 5px;
	box-shadow: 2px 2px 2px rgba(0,0,0,0.3);
}

.SubscriberField {
	display: inline-block;
	box-shadow: 2px 2px 2px rgba(0,0,0,0.1);
	margin-right: 5px;
	vertical-align: bottom;
}

.SubscriberField:last-child {
	margin: 0;
}

.SubscriberFieldTitle {
	font-family: 'Lato', sans-serif;
	box-shadow: 1px 2px 3px 0px rgba(0,0,0,0.4);
	z-index: 1;
	position: relative;
	display: inline-block;
	vertical-align: top;
	height: 100%;
	font-size: 9pt;
	background-color: #666;
	color: #eee;
	padding: 0 10px 0 10px;
	letter-spacing: 1px;
	-webkit-user-select: none;  /* Chrome all / Safari all */     /* Firefox all */      /* IE 10+ */
	user-select: none;          /* Likely future */
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
	height: 25px;
	line-height: 25px;
}

.SubscriberFieldInput {
	height: 25px;
	line-height: 25px;
	display: inline-block;
	vertical-align: middle;
}

.SubscriberFieldInput input {
	height: 25px;
	line-height: 25px;
	box-shadow: none;
	padding: 0 10px;
	border: none;
}

.SubscriberImportExport {	
	vertical-align: top;
	float: right;
	padding-left: 10px;	
}

.SubscriberImport, .SubscriberExport {	
	vertical-align: top;
	background-color: #888;
	box-shadow: 1px 1px 2px rgba(0,0,0,0.3);
	cursor: pointer;
	color: #eee;
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 3px rgba(0,0,0,0.5);
	transition: color 0.3s, background-color 0.3s;
}

.SubscriberImport {
	margin-bottom: 10px;
}

.SubscriberImport:hover, .SubscriberExport:hover {
	color: orange;
	background-color: #666;
}

.SubscriberImportIcon {
	display: inline-block;
	width: 63px;
	height: 60px;
	line-height: 60px;
	text-align: center;
	font-size: 30pt;
	vertical-align: middle;		
}

.SubscriberImportText  {
	display: inline-block;
	width: 70px;
	height: 60px;
	line-height: 20px;
	padding-top: 10px;
	text-align: center;
	vertical-align: middle;
	font-size: 9pt;
	text-transform: uppercase;
}

.CsvHelp p {
	padding: 20px 50px;
	font-size: 11pt;
}

.CsvHelp table {
	padding: 20px 50px;
}

.CsvSample {
	background-color: #eee;
	margin: 0 40px 20px 40px;
	font-family: monospace;
	font-size: 10pt;
	padding-top: 0;
}

.Descriptive {
	color: #999;
	font-style: italic;
	font-size: 9pt;
}




.Widgets {
    width: 1000px;
}

.Widgets .AdminComponentSection {
    width: 100%;
}

.Widgets .AdminComponentSection p {
    font-size: 10pt;
    margin: 10px 0;
}

.WidgetCode {    
    margin: 20px 0;
    background-color: #555;
    color: #eee;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.3);
    padding: 20px;
    position: relative;
    width: 100%;
    font-size: 9pt;
}

.Code {
    font-family: 'Courier New', Courier, monospace;    
}

.CopyButton {
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    transition: all 0.3s;
}

.CopyButton:hover {
    color: orange;
}


.Profile {
	width: 940px;
	height: 100%;
	margin: 0 auto;
}

.ProfileLogo {
	width: 250px;
	height: 250px;
	box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.3);
	background-color: #eee;
}

.ProfileLogo input {
	display: none;
}

.ProfileLogoImage {
	width: 100%;
	height: 100%;
	line-height: 250px;
	text-align: center;
	color: #fff;
	font-size: 140pt;
	cursor: pointer;
}

.ProfileLogoImage img {
	width: 100%;
	height: 100%;
}

.ProfileLogoImageChange {
	font-family: 'Lato', sans-serif;
	position: absolute;
	bottom: 25px;
	left: -3px;
	width: calc(100% + 6px);
	line-height: 30px;
	text-align: center;		
	color: #333;
	font-size: 9pt;
	text-transform: uppercase;
	text-shadow: none;
	background-color: rgba(255,255,255,0.8);
	box-shadow: 0px 3px 2px 0px rgba(0,0,0,0.3);
}

.ProfileMeta {
	width: 650px;
}

.ProfileField {
	position: relative;
	line-height: 35px;
	margin: 0 0 5px 0;
	box-shadow: 2px 2px 2px #eee;
}

.ProfileField:last-child {
	border: none;
}

.ProfileDescription {
	height: 100px;
}

.ProfileLabel {
	font-family: 'Lato', sans-serif;
	box-shadow: 1px 2px 3px 0px rgba(0,0,0,0.4);
	z-index: 1;
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 149px;
	height: 100%;
	font-size: 9pt;
	background-color: #666;
	color: #eee;
	padding: 0 2px 0 10px;
	letter-spacing: 1px;
	-webkit-user-select: none;  /* Chrome all / Safari all */     /* Firefox all */      /* IE 10+ */
	user-select: none;          /* Likely future */
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
}

.ProfileLabel .fab {
    padding-right: 15px;
    width: 30px;
}

.ProfileLabelText {
	display: inline-block;
	width: calc(100% - 20px);
	vertical-align: middle;
}

.ProfileLabelInfo {
	display: inline-block;
	width: 20px;
	cursor: help;
	font-size: 11pt;
	vertical-align: middle;
}

.ProfileDescription .ProfileLabel {
	width: 149px;
	height: 99px;
	line-height: 99px;
}

.ProfileInput {
	position: relative;
	display: inline-block;
	width: calc(100% - 150px);
	height: 100%;
	font-size: 9pt;
}

.ProfileInput input {
	height: 100%;
	width: 100%;
	padding: 10px;
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	border: 0;
}

.ProfileInput textarea {
	height: 99px;
	width: 100%;
	padding: 10px;
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	border: 0;
    resize: none;
    font-family: 'Roboto', sans-serif;
}

.BillingCurrentPlan {
	font-size: 14pt;
	padding: 20px 0;
}


.BillingUpgrade {
    margin: 20px 0;
    padding: 10px 20px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.3);
    
    cursor: pointer;
    background-color: orange;
    color: #eee;
    border-radius: 5px;
    opacity: 0.8;
    transition: opacity 0.3s;
}

.BillingUpgrade:hover {
    opacity: 1;
}

.BillingUpgradeLogo {
    font-size: 45pt;
    line-height: 90px;
    display: inline-block;
    width: 70px;
    vertical-align: middle;
}

.BillingUpgradeText {
    font-size: 18pt;
    display: inline-block; 
	padding-left: 30px;   
}

.BillingCancel {
    margin: 20px 0;
    padding: 10px 20px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.3);
    
    cursor: pointer;
    background-color: red;
    color: #eee;
    border-radius: 5px;
    opacity: 0.8;
    transition: opacity 0.3s;
}

.BillingCancel:hover {
    opacity: 1;
}

.BillingCancelLogo {
    font-size: 25pt;
    line-height: 50px;
    display: inline-block;
    width: 70px;
    vertical-align: middle;
}

.BillingCancelText {
    font-size: 12pt;
    display: inline-block; 
	padding-left: 30px;
    width: calc(100% - 70px);
    text-align: right;
}

.BillingPlan {
    display: inline;
    background: #333;
    color: #eee;
    padding: 10px;
    font-style: italic;
}

.BillingPlanDetails {
    box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
    padding: 20px;
    margin: 20px 0;
}

.BillingPlanDetailsLabel {
    display: inline-block;
    font-weight: bold;
    width: 150px;
    padding: 10px 0;
    border-bottom: 1px solid #888;
}

.BillingPlanDetailsStatus, .BillingPlanDetailsNextBilling {
    display: inline-block;
    width: 150px;
    text-align: right;
    padding: 10px 0;
    border-bottom: 1px solid #888;
}
.Contact {
	width: 940px;
	height: 100%;
    margin: 30px auto 0 auto;
}

.Contact .DashboardComponentSection {
	width: 100%;
}

.ContactForm {
	width: 100%;
}

.ContactForm .Button {
    margin: 20px auto 0 auto;
}

.ContactField {
	position: relative;
	line-height: 35px;
	margin: 0 0 5px 0;
    box-shadow: 2px 2px 2px #eee;
    border: 1px solid #ddd;
}

.ContactField:last-child {
	border: none;
}

.ContactMessage {
	height: 300px;
}

.ContactLabel {
	font-family: 'Lato', sans-serif;
	box-shadow: 1px 2px 3px 0px rgba(0,0,0,0.4);
	z-index: 1;
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 149px;
	height: 100%;
	font-size: 9pt;
	background-color: #666;
	color: #eee;
	padding: 0 2px 0 10px;
	letter-spacing: 1px;
	-webkit-user-select: none;  /* Chrome all / Safari all */     /* Firefox all */      /* IE 10+ */
	user-select: none;          /* Likely future */
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
}

.ContactMessage .ContactLabel {
	width: 149px;
	height: 299px;
}

.ContactInput {
	position: relative;
	display: inline-block;
	width: calc(100% - 150px);
	height: 100%;
	font-size: 9pt;
}

.ContactInput input {
	height: 100%;
	width: 100%;
	padding: 10px;
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	border: 0;
}

.ContactInput textarea {
	height: 299px;
	width: 100%;
	padding: 10px;
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	border: 0;
    resize: none;
    font-family: 'Roboto', sans-serif;
}

.Sending {
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: rgba(255, 255, 255, 0.3);
}
.CatalogWrapper {
    background-color: white;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;
}

.Catalog {
    min-width: 320px;
    max-width: 500px;
    min-height: 100vh;
    margin: 0 auto;
    font-family: 'Lato', sans-serif;
    text-align: center;
    background-color: #111111;
    color: #eee;
    box-shadow: 0 -3px 5px 2px rgba(0, 0, 0, 0.3);
    padding-bottom: 20px;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 0.95;
}

.CatalogBackground {
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.CatalogUserInfo {
    width: 100%;
}

.CatalogUserLogo {
    position: relative;
    width: 100%;
    padding: 20px;
    margin: 0;
}

.CatalogUserLogo img {
    width: 100%;
    height: auto;
    padding: 0;
    margin-bottom: -5px;
}

.CatalogUserName {    
    font-size: 27pt;
    height: 60px;
    line-height: 60px;
    background-color: darkorange;
    margin: 0;
}


.CatalogUserSocials {
    margin: 15px 0;
    text-align: center;
    color: #eee !important;
}

.CatalogUserSocials a {
    font-size: 12pt;
    transition: all 0.3s;
    color: #eee;
}

.CatalogUserSocials a:hover {
    color: orange;
}

.CatalogUserSocial {
    display: inline-block;
    margin: 0 10px;
    font-size: 18pt;
    cursor: pointer;
    transition: all 0.3s;
}

.CatalogUserSocial:hover {
    color: orange;
}

.CatalogUserDescription {
    text-align: justify;
    padding: 0 30px;
    font-size: 10pt;
    line-height: 20px;
}

.CatalogReleases {
    width: 100%;
}

.CatalogReleasesTitle {
    font-size: 25pt;
    height: 60px;
    line-height: 60px;
    background-color: #222;
    border-bottom: 1px solid #888;
    border-top: 1px solid #888;
    margin-bottom: 10px;
}

.CatalogReleaseList {
    width: 100%;
}

.CatalogRelease {
    display: inline-block;
    width: 30%;
    margin: 1%;
    cursor: pointer;
    vertical-align: top;
}

.CatalogRelease:hover .CatalogReleaseTitle {
    background-color: #333;
}

.CatalogReleaseCover {
    width: 100%;
}

.CatalogReleaseCover img {
    width: 100%;
    height: auto;
}

.CatalogReleaseInfo {
    width: 100%;
}

.CatalogReleaseTitle {
    display: none;
}

.CatalogReleaseArtist {
    display: none;
}


.MinipageEG {
    font-family: 'Lato', sans-serif;
    font-size: 9pt;
    color: #eee;
    margin: 20px 0 0 0;
}

.MinipageEG a {
    color: #eee;
}

.MinipageEG a:hover {
    color: #999;
}
.LinkPageWrapper {
    background-color: white;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;
}

.LinkPageBackground {
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.LinkPage {
    min-width: 320px;
    max-width: 500px;
    min-height: 100vh;
    margin: 0 auto;
    font-family: 'Lato', sans-serif;
    text-align: center;
    background-color: #111111;
    color: #eee;
    box-shadow: 0 -3px 5px 2px rgba(0, 0, 0, 0.3);
    padding-bottom: 20px;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 0.95;
}

.LinkPageUserInfo {
    width: 100%;
}

.LinkPageUserLogo {
    position: relative;
    width: 100%;
    padding: 20px;
    margin: 0;
}

.LinkPageUserLogo img {
    width: 100%;
    height: auto;
    padding: 0;
    margin-bottom: -5px;
}

.LinkPageUserName {    
    font-size: 27pt;
    height: 60px;
    line-height: 60px;
    background-color: darkorange;
    margin: 0;
}


.LinkPageUserSocials {
    width: 100%;
    text-align: center;
    color: #eee !important;    
}

.LinkPageUserSocials a {
    font-size: 12pt;
    transition: all 0.3s;
    color: #eee;
}

.LinkPageUserSocials a:hover {
    color: orange;
}

.LinkPageUserSocial {
    border-bottom: 1px solid #aaa;
    background-color: #111;
    font-size: 17pt;
    cursor: pointer;
    transition: all 0.3s;
    padding: 15px;
    text-align: left;
}

.LinkPageUserSocial span {
    width: 50px;
    text-align: center;
}

.LinkPageUserSocial:hover {
    color: orange;
    background-color: #222;
}

.LinkPageUserDescription {
    text-align: justify;
    padding: 0 30px;
    font-size: 10pt;
    line-height: 20px;
    margin-top: 50px;
}

.LinkPageCustomLinks {
    width: 100%;
    margin: 0 0 15px 0;
    text-align: center;
    color: #eee !important;  
}

.LinkPageCustomLink {
    border-bottom: 1px solid #aaa;
    background-color: #111;
    font-size: 17pt;
    cursor: pointer;
    transition: all 0.3s;
    padding: 15px;
    text-align: left;
}

.LinkPageCustomLink span {
    width: 50px;
    text-align: center;
}

.LinkPageCustomLink:hover {
    color: orange;
    background-color: #222;
}
.Redirect {
    width: 100%;
    height: 100vh;
}

.NDATitle {
    font-size: 12pt;
    font-weight: bold;
    text-align: center;
}

.NDA {
    width: 90%;
    margin: 10px auto;
    height: 500px;
    overflow-y: scroll;
    padding: 0 10px;
    text-align: justify;
    font-size: 10pt;
    border: 1px dotted #ddd;
}

.NDA p {
    margin: 4px 0;
}
.DefaultWidget {
    width: 500px;
    height: 50px;
    overflow: hidden;
    padding: 20px 0 0 0;
}

.DefaultWidget .SubscribeInput {
    display: inline-block;
    width: 150px;
    margin-right: 10px;
    height: 30px;
    vertical-align: top;
}

.DefaultWidget .SubscribeInput .InputLabel {
    position: absolute;
    top: -12px;
    left: 0;
    font-size: 8pt;
    height: 10px;
    line-height: 10px;
}

.DefaultWidget .SubscribeInput input {
    border-radius: 0;
    padding: 2px 5px;
    height: 30px;
    font-size: 9pt;
}

.DefaultWidget .SubscribeButton {
    display: inline-block;
    width: 90px;
    background-color: #333;
    color: #eee;
    padding: 0 5px;
    height: 30px;
    text-align: center;
    vertical-align: top;
    font-size: 9pt;
    cursor: pointer;
    line-height: 30px;
    transition: all 0.3s;
    -webkit-user-select: none;
            user-select: none;
}

.DefaultWidget .SubscribeButton:hover {
    background-color: #888;
}

.DefaultWidget .SubscribeButton:active {
    background-color: #eee;
    color: #333;
}
.Stats {
    width: 940px;
	height: 100%;
	margin: 0 auto;
}

.Stats select {
	width: 100%;
	margin-left: 0;
}

.Stats .DashboardComponentSection {
	width: 100%;
}

.StatsUpgrade {
	text-align: left;
}

.StatsUpgrade p {
	font-size: 14pt;
	padding: 20px 0;
}


.StatsUpgradeAction {
    margin: 20px 0;
    padding: 10px 20px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.3);
    width: 330px;
    cursor: pointer;
    background-color: orange;
    color: #eee;
    border-radius: 5px;
    opacity: 0.8;
    transition: opacity 0.3s;
}

.StatsUpgradeAction:hover {
    opacity: 1;
}

.StatsUpgradeActionLogo {
    font-size: 45pt;
    display: inline-block;
    width: 70px;
    vertical-align: middle;
}

.StatsUpgradeActionText {
    font-size: 20pt;
    display: inline-block; 
	padding-left: 30px;   
}
.Feed {
    width: 100%;
    position: relative;
}

.FeedHeader {
    width: 100%;
    height: 50px;
    background-color: #333;    
    position: fixed;
    z-index: 2;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
}

.FeedHeaderMenuButton {
    width: 50px;
    font-size: 23pt;
    line-height: 50px;
    margin-left: 10px;
    cursor: pointer;
    color: orange;
    transition: color 0.3s;
}

.FeedHeaderMenuButton:hover {
    color: lightsalmon;
}

.FeedHeaderMenu {    
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 50px;
    left: 0;
	z-index: 1;
	width: 300px;
}

.FeedHeaderMenuItem {
    font-family: 'Lato', sans-serif;
    border-bottom: 1px solid #555;
    padding: 15px 30px;
    color: #eee;
    cursor: pointer;
    background-color: #333;
    transition: background-color 0.3s;
}

.FeedHeaderMenuItem:hover {
    background-color: #444;
}

.NoClick {
    cursor: default;
}

.NoClick:hover {
    background-color: #333;
}

.Special {
    background-color: orange;
}

.Special:hover {
    background-color: lightsalmon;
}

.FeedHeaderMenuItem:last-child {
    border-bottom: none;
}

.FeedReleases {
    top: 50px;
}

.FilterInput {
	position: relative;	
	width: 100%;
	height: 100%;
    font-size: 9pt;
    margin-top: 5px;
}

.FilterInput input {
    height: 100%;
	width: 100%;
	padding: 10px;
	margin: 0;
	border-radius: 0;
	box-shadow: none;
	border: 0;
}

.ReactTags__tags {
	height: 100%;
	line-height: 35px;
}

.FeedHeaderMenu .ReactTags__tagInput {
	display: block;
}

.ReactTags__tagInputField {
	width: 100%;
}
.ReactTags__selected {
    padding-left: 0;
}

.ReactTags__selected .ReactTags__tag {
	font-family: 'Lato', sans-serif;
	background-color: orange;
	font-size: 10pt;
	height: 25px;
	line-height: 25px;
	color: #eee;
	padding: 0 0 0 10px;
	margin: 0 2px;
	border-radius: 3px;
	vertical-align: middle;
}

.ReactTags__selected .ReactTags__remove {
	color: #fff;
	margin-left: 8px;
	height: 25px;
	line-height: 25px;
	font-size: 12pt;
	cursor: pointer;
	border-left: 1px dashed #eee;
	padding: 0 5px;
	display: inline-block;
	background-color: rgba(0,0,0,0.1);
	border-radius: 0 3px 3px 0;
}

.ReactTags__suggestions {
	position: absolute;
	z-index: 5;	
	background: #eee;
	color: #333;
	overflow: hidden;
	cursor: pointer;
	padding: 0;
}

.ReactTags__suggestions li {
	padding: 3px 10px;	
	transition: background-color 0.2s;
	margin: 0 3px;
	box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.1);
}

.ReactTags__activeSuggestion {
	background-color: #fafafa;
}




@media only screen and (max-width: 479px) {
    .FeedRelease {
        width: 98%;
    }
}


@media only screen and (min-width: 480px) and (max-width: 1000px) {
    .FeedRelease {
        width: 48%;
    }
}

@media only screen and (min-width: 1001px) and (max-width: 1680px) {
    .FeedRelease {
        width: 31%;
    }
}

@media only screen and (min-width: 1681px) {
    .FeedRelease {
        width: 23%;
    }
}

.FeedRelease {
    display: inline-block;
    margin: 1%;
    cursor: pointer;
    vertical-align: top;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.1);
}

.FeedRelease:hover .FeedReleaseTitle {
    background-color: #333;
}

.FeedReleaseCover {
    width: 100%;
}

.FeedReleaseCover img {
    width: 100%;
    height: auto;
}

.FeedReleaseInfo {
    width: 100%;
}

.FeedReleaseTitle {
    font-family: 'Lato', sans-serif;
    background-color: #333;
    color: #eee;
    padding: 7px;
    font-size: 12pt;
    text-align: center;
    transition: all 0.3s;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.3);
}

.FeedReleaseArtist {
    font-family: 'Lato', sans-serif;
    text-align: center;
    font-size: 10pt;
    padding: 5px;
}
.EditCatalog {
    width: 940px;
	height: 100%;
	margin: 0 auto;
}

.CatalogPreview {
    display: inline-block;
    width: 630px;
    max-height: 70vh;
    overflow-y: scroll;
    box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.4);
    border: 1px solid #eee;
}

.CatalogEdit {
    display: inline-block;
    width: 270px;
    box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.4);
    border: 1px solid #eee;
    min-height: 70vh;
}

.PublicUrl {

}

.PublicUrlTitle {
    display: inline-block;
    vertical-align: top;
    padding: 0 10px;
    background-color: #444;
    color: #eee;
    margin-bottom: 7px;
    text-shadow: 0px 1px 0px rgba(0,0,0,0.8), 0px 1px 2px rgba(0,0,0,0.5), 0px 2px 6px rgba(0,0,0,0.5);
    box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.4);
    z-index: 2;
    font-size: 11pt;
    height: 35px;
    line-height: 35px;
    margin-right: -4px;
}

.PublicUrlInput {
    display: inline-block;
    vertical-align: top;
}

.PublicUrlInput input {
    height: 35px;
    line-height: 35px;
    padding: 0;
    width: 400px;
    margin-right: 15px;
    padding: 0 10px;
}

.PublicUrlButton {
    display: inline-block;
    vertical-align: top;
}

.CatalogUploadImage input {
    display: none;
}

.CatalogImage {
	text-align: center;
	color: #fff;
    cursor: pointer;
    width: 220px;
    margin: 5px auto;
}

.CatalogImageChange {
    font-family: 'Lato', sans-serif;	
	line-height: 30px;
	text-align: center;		
	color: #333;
	font-size: 9pt;
	text-transform: uppercase;
	text-shadow: none;
	background-color: rgba(255,255,255,0.8);
    box-shadow: 0px 3px 2px 0px rgba(0,0,0,0.3);
    transition: all 0.3s;
}

.CatalogImageChange:hover {
	background-color: #ddd;
}


.EditLinkPage {
    width: 940px;
	height: 100%;
	margin: 0 auto;
}

.LinkPagePreview {
    display: inline-block;
    width: 630px;
    max-height: 70vh;
    overflow-y: scroll;
    box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.4);
    border: 1px solid #eee;
}

.LinkPageEdit {
    display: inline-block;
    width: 270px;
    box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.4);
    border: 1px solid #eee;
    min-height: 70vh;
}

.PublicUrl {

}

.PublicUrlTitle {
    display: inline-block;
    vertical-align: top;
    padding: 0 10px;
    background-color: #444;
    color: #eee;
    margin-bottom: 7px;
    text-shadow: 0px 1px 0px rgba(0,0,0,0.8), 0px 1px 2px rgba(0,0,0,0.5), 0px 2px 6px rgba(0,0,0,0.5);
    box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.4);
    z-index: 2;
    font-size: 11pt;
    height: 35px;
    line-height: 35px;
    margin-right: -4px;
}

.PublicUrlInput {
    display: inline-block;
    vertical-align: top;
}

.PublicUrlInput input {
    height: 35px;
    line-height: 35px;
    padding: 0;
    width: 400px;
    margin-right: 15px;
    padding: 0 10px;
}

.PublicUrlButton {
    display: inline-block;
    vertical-align: top;
}

.LinkPageUploadImage input {
    display: none;
}

.LinkPageImage {
	text-align: center;
	color: #fff;
    cursor: pointer;
    width: 220px;
    margin: 5px auto;
}

.LinkPageImageChange {
    font-family: 'Lato', sans-serif;	
	line-height: 30px;
	text-align: center;		
	color: #333;
	font-size: 9pt;
	text-transform: uppercase;
	text-shadow: none;
	background-color: rgba(255,255,255,0.8);
    box-shadow: 0px 3px 2px 0px rgba(0,0,0,0.3);
    transition: all 0.3s;
}

.LinkPageImageChange:hover {
	background-color: #ddd;
}

.LinkPageLinks {

}

.LinkPageLink {
    width: 220px;
    margin: 15px auto;
}

.LinkPageLinkDelete {
    color: red;
    position: absolute;
    top: 22px;
    right: -22px;
    font-size: 15pt;
    cursor: pointer;
    transition: all 0.3s;
}
.LinkPageLinkDelete:hover {
    color: darkred;
}

.LinkPageLink input {
    padding: 10px;
	margin: 0;
	border-radius: 0;
    box-shadow: none;
    height: 30px;    
    margin: 2px 0;
}

.LinkPageAddLink {
    width: 220px;
    margin: 30px auto 0 auto;
    border-top: 1px dotted #333;
    padding-top: 20px;
}

.LinkPageAddLink input {
    padding: 10px;
	margin: 0;
	border-radius: 0;
    box-shadow: none;
    height: 30px;    
    margin: 2px 0;
}

.LinkPageAddLink .Button {
    padding: 10px;
    box-shadow: none;   
    margin: 6px auto;    
}

.LinkPageAddLinkLabel {
    font-size: 8pt;
    margin-top: 6px;
}


