.MinipageWidget {
    width: 500px;
    height: 50px;
    overflow: hidden;
    padding: 20px 0 0 0;
}

.MinipageWidget .SubscribeInput {
    display: inline-block;
    width: 150px;
    margin-right: 10px;
    height: 30px;
    vertical-align: top;
}

.MinipageWidget .SubscribeInput .InputLabel {
    position: absolute;
    top: -12px;
    left: 0;
    font-size: 8pt;
    height: 10px;
    line-height: 10px;
}

.MinipageWidget .SubscribeInput input {
    border-radius: 0;
    padding: 2px 5px;
    height: 20px;
    font-size: 9pt;
    box-shadow: none;
}

.MinipageWidget .SubscribeButton {
    display: inline-block;
    width: 90px;
    background-color: #333;
    color: #eee;
    padding: 0 5px;
    height: 20px;
    text-align: center;
    vertical-align: top;
    font-size: 9pt;
    cursor: pointer;
    line-height: 20px;
    transition: all 0.3s;
    user-select: none;
}

.MinipageWidget .SubscribeButton:hover {
    background-color: #888;
}

.MinipageWidget .SubscribeButton:active {
    background-color: #eee;
    color: #333;
}

@media only screen and (max-width: 500px) {
    .MinipageWidget {
        display: none;
    }
}