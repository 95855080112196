.Reset {
    padding-top: 150px;
}

.ResetLogo {
    width: 400px;
    height: 402px;
    background-image: url("/overviewsquare.webp");
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto;
}

.Reset .Form p {
    font-size: 10pt;
    margin-bottom: 20px;
}


.FormField .Error {
	font-family: 'Roboto', sans-serif;
	position: absolute;
	line-height: 50px;
	top: 30px;
	left: 101%;
	color: red;
	font-size: 8pt;
	width: 200px;
	text-align: left;
}

.ResetButton {
    margin-top: 40px;
}

.Reset .Form .ResetButton .Button {
    margin: 0 auto;
}

.ResetSuccessMessage {
    margin: 30px auto;
    width: 400px;
    font-size: 10pt;
    text-align: center;
}

.ResetSuccessMessage p a {
    color: orange;
}

.ResetSuccessMessage p a:hover {
    text-decoration: underline;
}

.Change {
    width: 700px;
	height: 600px;
	text-align: center;
}
