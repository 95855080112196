.DashboardLanding {
    width: 1100px;
	height: 100%;
	margin: 0 auto;
}

.DashboardLandingLatest {
    width: 550px;
    height: 100%;
    display: inline-block;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
}


.DashboardLandingLatestTitle {
    font-family: 'Lato', sans-serif;    
    background-color: #aaa;
    color: #fff;
    width: 100%;
    height: 150px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.8);
    cursor: pointer;
    transition: color 0.3s;
}

.DashboardLandingLatestTitle:hover {
    color: orange;
}

.DashboardLandingLatestTitleCover {
    width: 130px;
    height: 130px;
    position: absolute;
    left: 10px;
    top: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.8);
}

.DashboardLandingLatestRelease {
    position: absolute;
    top: 10px;
    right: 10px;
    text-align: right;
    text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
    width: calc(100% - 170px);
}

.DashboardLandingLatestReleaseAnnounce {
    font-size: 17pt;
}

.DashboardLandingLatestReleaseTitle {
    font-size: 20pt;
}

.DashboardLandingLatestReleaseArtist {
    font-size: 15pt;
}

.DashboardLandingLatestTitleCover img {
    height: 100%;
    width: 100%;
}

.DashboardLandingSchedule {
    display: inline-block;
    vertical-align: top;
    margin-left: 80px;
    width: 450px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
}

.DashboardLandingScheduleTitle {
    font-family: 'Lato', sans-serif;
    font-size: 30pt;
    text-align: center;
    background-color: darkorange;
    color: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.8);
    text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
    padding: 20px 0;
}

.DashboardLandingScheduleItem {
    width: 100%;
    margin: 10px 0;
    background-color: #eee;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.5);
    padding: 10px;
    height: 100px;
    cursor: pointer;
    transition: box-shadow 0.3s;
}

.DashboardLandingScheduleItem:hover {
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.8);
}

.DashboardLandingScheduleItemDate {
    position: absolute;
    bottom: 35px;
    right: 10px;

}

.DashboardLandingScheduleItemRelease {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.DashboardLandingScheduleItemReleaseCover {
    width: 100px;
    height: 100px;
    background-color: rgba(255,255,255,0.5);
}

.DashboardLandingScheduleItemReleaseCover img {
    width: 100%;
    height: 100%;
}

.DashboardLandingScheduleItemReleaseTitle {
    position: absolute;
    top: 10px;
    left: 110px;
    font-size: 15pt;
}

.DashboardLandingScheduleItemReleaseArtist {
    position: absolute;
    top: 30px;
    left: 110px;
}

.DashboardLandingScheduleItemTask {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 18pt;
    font-family: 'Roboto', sans-serif;
}

.DashboardLandingLatestEmpty {
    font-size: 14pt;
    padding: 20px;
    text-align: left;
}

.DashboardLandingScheduleEmpty {
    font-size: 14pt;
    padding: 20px;
    text-align: center;
}

.DashboardLandingNewRelease {
    margin: 20px auto;
    padding: 10px 20px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.3);
    width: 370px;
    cursor: pointer;
    background-color: orange;
    color: #eee;
    border-radius: 5px;
    opacity: 0.8;
    transition: opacity 0.3s;
}

.DashboardLandingNewRelease:hover {
    opacity: 1;
}

.DashboardLandingNewReleaseLogo {
    font-size: 50pt;
    display: inline-block;
    width: 70px;
    vertical-align: middle;
}

.DashboardLandingNewReleaseText {
    font-size: 20pt;
    display: inline-block;    
}