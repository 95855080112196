.Dashboard {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	min-width: 1300px;
}

.DashboardComponent {
	position: absolute;
	top: 125px;
	left: 245px;	
	min-height: calc(100% - 125px);
	padding: 0;
}

.DashboardComponentHeader {
	position: fixed;
	top: 45px;
	left: 245px;
	height: 50px;
	font-family: 'Lato', sans-serif;
	line-height: 50px;		
	text-align: center;			
	z-index: 3;
}

.DashboardComponentTitle {
	display: inline-block;
	color: #eee;
	font-size: 15pt;
	text-transform: capitalize;
	font-weight: bold;
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.8);
	background-color: #666;	
	padding: 0 20px;
	vertical-align: top;
	margin: 0 10px 0 0;
}

.DashboardComponentTitle .fa {
	padding-right: 20px;
}

.DashboardComponentMenu {
	display: inline-block;
	vertical-align: top;
}

.DashboardComponentMenuItem {
	font-family: 'Lato', sans-serif;
	display: inline-block;
	cursor: pointer;
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.8);
	margin: 0 5px;
	padding: 0 10px;
	background-color: #444;
	color: #eee;
	font-size: 10pt;
	height: 40px;
	line-height: 40px;
	vertical-align: top;
	transition: color 0.3s, background-color 0.3s;
	user-select: none;
}

.DashboardComponentMenuItemInactive {
	font-family: 'Lato', sans-serif;
	display: inline-block;	
	box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.8);
	margin: 0 5px;
	padding: 0 10px;
	background-color: #777;
	color: #eee;
	font-size: 10pt;
	height: 40px;
	line-height: 40px;
	vertical-align: top;
	transition: color 0.3s, background-color 0.3s;
	user-select: none;
}

.Spaced {
	margin-left: 40px;
}

.Unsaved .fa {
	color: darkorange;
}

.DashboardComponentMenu .fa {
	margin-right: 5px;
}

.DashboardComponentMenuItem:hover {
	color: orange;
	background-color: #555;
}

.DashboardComponentMenuItem:active {
	background-color: #666;
}

.DashboardComponentSection {
	position: relative;
	display: inline-block;
	vertical-align: top;
	margin-right: 20px;
	margin-bottom: 20px;
}

.DashboardComponentSectionTitle {
	position: relative;
	font-size: 13pt;
	font-family: 'Lato', sans-serif;
	text-align: center;
	padding: 8px 10px;
	background-color: #444;
	color: #eee;
	margin-bottom: 7px;
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
	box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.4);
	z-index: 2;
}

.DashboardMenuTop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 45px;
	border-bottom: 1px solid #000;
	box-shadow: 0px 1px 3px 0px #444;
	background-color: #333;
	z-index: 5;
}

.DashboardMenuTopLogo {
	position: fixed;
	top: 5px;
	left: 0;
	line-height: 60px;
	font-size: 30pt;
	color: white;
	width: 65px;
	text-align: right;
	padding-right: 15px;
	text-shadow: 0px 2px 1px rgba(0,0,0,0.4),
             0px 4px 7px rgba(0,0,0,0.1),
             0px 9px 11px rgba(0,0,0,0.1);
	cursor: pointer;
	transition: color 0.3s, width 0.3s;	
	border-radius: 0 40px 40px 0;
	background-color: orange;
	box-shadow: 0px 1px 3px 0px #222;
	z-index: 6;
}

.DashboardMenuTopLogo:hover {
	color: #333;
	width: 80px;
}

.DashboardMenuTopItems {
	position: absolute;
	top: 0;
	right: 20px;
	z-index: 6;
}

.DashboardMenuTopItem {
	position: relative;
	line-height: 45px;
	display: inline-block;
	font-size: 16pt;
	min-width: 45px;
	text-align: center;
	text-shadow: 0px 2px 1px rgba(0,0,0,0.4),
             0px 4px 7px rgba(0,0,0,0.1),
             0px 9px 11px rgba(0,0,0,0.1);
	color: #fff;
	cursor: pointer;
	transition: color 0.3s;
}

.DashboardMenuTopProfileName {
	font-size: 11pt;
	display: inline-block;
	height: 45px;
	line-height: 45px;
	vertical-align: top;
}

.DashboardMenuTopItemHome {
	position: relative;
}

.DashboardMenuTopItemNotifications {
	position: relative;
	margin-right: 30px;
}

.DashboardMenuTopItem:hover {
	color: orange;
}

.DashboardMenuSide {
	position: fixed;
	top: 45px;
	left: 0;
	width: 215px;
	height: calc(100% - 45px);
	background-color: #333;
	border-right: 1px solid #111;
	box-shadow: 2px 2px 5px 0px #111;
	padding-top: 30px;
	z-index: 4;
}

.DashboardMenuTab {
	position: relative;
	margin-bottom: 10px;
}

.DashboardMenuTabTitle {
	position: relative;
	color: #eee;
	width: calc(100% + 5px);
	font-family: 'Roboto', sans-serif;
	text-transform: capitalize;
	font-size: 11pt;
	letter-spacing: 1px;
	padding: 0 15px;
	line-height: 40px;
	cursor: pointer;
	box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.4), 1px -2px 3px 0px rgba(0,0,0,0.3);
	background-color: #444;
	transition: background-color 0.3s, color 0.3s;
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
	z-index: 3;
}

.DashboardMenuTabTitle .fa {
	position: relative;
	font-size: 11pt;
	padding-right: 10px;
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 3px 4px rgba(0,0,0,0.5);
}

.DashboardMenuTabTitle:hover {
	background-color: #555;
	color: orange;
}

.DashboardMenuItem {	
	position: relative;
}

.DashboardMenuItemText {
	position: relative;
	font-family: 'Roboto', sans-serif;
	color: #eee;
	font-size: 10pt;
	padding-left: 25px;
	line-height: 30px;
	cursor: pointer;
	border-bottom: 1px solid #222;
	background-color: #333;
	transition: background-color 0.3s, color 0.3s;
	z-index: 2;
}

.DashboardMenuItemText .fa {
	padding-right: 8px;
	width: 25px;
	text-align: center;
	text-shadow: 0px 1px 0px rgba(0,0,0,0.8),
             0px 1px 2px rgba(0,0,0,0.5),
             0px 2px 6px rgba(0,0,0,0.5);
}

.DashboardMenuItemText:hover {
	background-color: #555;
	color: orange;
}

.DashboardMenuItem .Tooltip {
}

.DashboardMenuBugReport {
	position: absolute;
	bottom: 10px;
	height: 30px;
	width: 100%;
	background-color: red;
}

.AccountMenu {
	position: absolute;
	right: -10px;
	top: 6px;
	width: 200px;
	background-color: rgba(0, 0, 0, 0);
	padding-top: 40px;
	z-index: -1;	
}

.AccountMenuItem {
	color: #eee;
	font-family: 'Lato', sans-serif;
	background-color: #333;
	font-size: 12pt;
	transition: color 0.3s, background-color 0.3s;
	text-align: left;
	padding: 0 10px;
	height: 50px;
	line-height: 50px;
	box-shadow: 0px 1px 3px 0px #444;
	border-bottom: 1px solid #444;
	text-shadow: 0px 1px 0px rgba(0,0,0,0.4),
			0px 1px 2px rgba(0,0,0,0.2),
			0px 2px 6px rgba(0,0,0,0.2);
}
.AccountMenuItem:hover {
	color: orange;
	background-color: #444;
}

.AccountMenuItem .fa {
	position: relative;
	font-size: 10pt;
	padding-left: 10px;
	width: 40px;
	height: 50px;
	line-height: 50px;
}

.Notifications {
	position: absolute;
	right: -30px;
	top: 6px;
	width: 300px;
	background-color: rgba(0, 0, 0, 0);
	padding-top: 40px;
	z-index: -1;
}

.NewNotificationsMarker {
	width: 8px;
	height: 8px;
	border-radius: 5px;
	background-color: orange;
	position: absolute;
	top: 8px;
	right: 8px;	
}

.Notification {
	color: #fff;
	font-family: 'Lato', sans-serif;
	background-color: #333;
	font-size: 10pt;
	line-height: 30px;
	transition: color 0.3s, background-color 0.3s;
	text-align: justify;
	padding: 15px;
	height: auto;
	box-shadow: 0px 1px 2px 0px #444;
	border-bottom: 2px solid #444;
	text-shadow: none;
	transition: all 0.3s;
}

.Notification:hover {
	background-color: #444;
}

.NewNotification {
	border-left: 2px solid orange;
}

.Validating {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	
}

.ValidatingInfo {
	position: absolute;
	top: 45%;
	left: 0;
	width: 100%;
	text-align: center;
	font-family: 'Lato', sans-serif;
	text-transform: uppercase;
	font-size: 9pt;
	color: orange;
}

.ValidatingInfo img {
	width: 50px;
	height: 50px;
	margin: 20px;
}

.DashboardComponentMenuInfo {
	display: inline-block;
	text-transform: uppercase;
	margin-left: 30px;
	font-size: 9pt;
	vertical-align: top;
	background-color: rgba(255, 255, 255, 0.7);
	padding: 0 20px;
}

.Saving {
	color: orange;
}

.Saved {
	color: #555;
}

.saving-enter {
	opacity: 0.01;
}
  
.saving-enter.saving-enter-active {
	opacity: 1;
	transition: opacity 300ms ease-in;
}
  
.saving-leave {
	opacity: 1;
}
  
.saving-leave.saving-leave-active {
	opacity: 0.01;
	transition: opacity 300ms ease-in;
}