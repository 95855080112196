.Forgot {
	width: 600px;
	min-width: 600px;
	height: 400px;
	text-align: center;
}

.Forgot .Button {
	margin: 0 auto;
}


.ForgotRightTitle {
	font-family: 'Lato', sans-serif;
	font-size: 20pt;
	text-align: center;
	width: 100%;
	line-height: 100px;
}

.Forgot .Message {
	color: black;
	font-size: 11pt;
	padding: 10px;
}

.RegisterLink, .SignInLink {
	padding: 5px 0;
	font-size: 8pt;
	color: orange;
	cursor: pointer;
}

.RegisterLink:hover, .SignInLink:hover {
	text-decoration: underline;	
}

@media only screen and (max-width: 1279px) {
	.Forgot {
		width: 90%;
		min-width: 0px;
		height: auto;
		padding-bottom: 10px;
	}

	
	.ForgotRightTitle {
		font-size: 15pt;		
		line-height: 60px;
	}
}
