.StatSection {
    width: 100%;
    position: relative;
}

.StatsVisits {
    width: 100%;
    padding: 0 10px;
    margin: 7px 0 0 0;
    background-color: #eee;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.8);
}

.StatsMap {
    width: 100%;
    padding: 0 10px;
    margin: 7px 0 0 0;
    background-color: #eee;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.8);
}

.StatsCounts {
    width: calc(50% - 7px);
    margin: 7px 6px 0 0;
    padding: 0 10px;
    background-color: #eee;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.8);
    display: inline-block;
}

.StatsMails {
    width: 50%;
    margin: 7px 0 0 0;
    padding: 0 10px;
    background-color: #eee;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.8);
    display: inline-block;
}

.ChartTitle {
    width: 100%;
    font-family: 'Lato', sans-serif;
    font-size: 18pt;
    text-align: left;
    padding: 10px 0px 5px;    
    color: #333;
    margin: 10px 0 5px 0;
}

.ChartSubTitle {
    width: 100%;
    font-family: 'Lato', sans-serif;
    font-size: 11pt;
    text-align: left;
    padding: 0px 0px 5px;
    color: #555;
    margin-bottom: 10px;
    border-bottom: 1px solid #aaa;
}

.StatsMapInner {

}