.Redirect {
    width: 100%;
    height: 100vh;
}

.NDATitle {
    font-size: 12pt;
    font-weight: bold;
    text-align: center;
}

.NDA {
    width: 90%;
    margin: 10px auto;
    height: 500px;
    overflow-y: scroll;
    padding: 0 10px;
    text-align: justify;
    font-size: 10pt;
    border: 1px dotted #ddd;
}

.NDA p {
    margin: 4px 0;
}