.Widgets {
    width: 1000px;
}

.Widgets .AdminComponentSection {
    width: 100%;
}

.Widgets .AdminComponentSection p {
    font-size: 10pt;
    margin: 10px 0;
}

.WidgetCode {    
    margin: 20px 0;
    background-color: #555;
    color: #eee;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.3);
    padding: 20px;
    position: relative;
    width: 100%;
    font-size: 9pt;
}

.Code {
    font-family: 'Courier New', Courier, monospace;    
}

.CopyButton {
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    transition: all 0.3s;
}

.CopyButton:hover {
    color: orange;
}

