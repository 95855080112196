.Preview {
    width: 90%;
    max-width: 1200px;
    height: 90%;
}

.PreviewLeft {
    display: inline-block;
    vertical-align: top;
    height: calc(100% - 30px);
    width: 70%;
    box-shadow: 1px 0 3px 0px rgba(0, 0, 0, 0.3);
    z-index: 2;
    overflow-y: scroll;
}

.PreviewLeft iframe {
    height: 200%;
    min-height: 1500px;
    width: 100%;
    border: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.PreviewRight {
    display: inline-block;
    vertical-align: top;
    height: calc(100% - 30px);
    width: 30%;
    background-color: #fafafa;
    overflow-y: scroll;
}

.PreviewRightTitle {
    font-family: 'Lato', sans-serif;
    font-size: 14pt;
    letter-spacing: 2px;
    word-spacing: 8px;
    text-align: center;
    padding: 20px 0;
    background-color: #222;
    color: #eee;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
    margin-top: 10px;
    margin-bottom: 20px;
}

.PreviewRightBlock {
    margin: 10px 0;
}

.PreviewRightTestTitle {
    height: 40px;
    line-height: 40px;
    background-color: #555;
    color: #eee;
    font-size: 10pt;
    letter-spacing: 1px;
    text-align: center;
}

.PreviewRightTestInput {
    width: 90%;
    margin: 10px auto;
    height: 30px;
    line-height: 30px;
    font-size: 10pt;
}

.PreviewRightTestInput input {
    height: 30px;
    line-height: 30px;
    display: inline-block;
    width: 130px;
    padding: 5px;
    margin-left: 5px;
    font-size: 10pt;
}

.PreviewRightTestButton .Button {
    margin-left: 20px;
    display: inline-block;
    vertical-align: middle;
}

.PreviewRightSend {
    position: absolute;
    width: 100%;
    bottom: 30px;
}

.PreviewRightSendText {
    padding: 20px;
    text-align: justify;
    font-size: 10pt;
    font-family: 'Roboto', sans-serif;
}

.PreviewOption {
    width: 100%;
    margin: 15px 0;
}

.PreviewOptionLabel {
    text-align: center;
    font-size: 9pt;    
    background-color: #333;
    color: #eee;
    padding: 10px;
    width: 220px;
    margin: 0 auto 6px auto;
    font-family: 'Lato', sans-serif;
}

.PreviewOptionInput {
    width: 90%;
    margin: 0 auto;    
}

.CoverArtOption {
    text-align: center;
    padding: 10px 0;
    font-size: 10pt;
}

.CoverArtOption input {
    height: 13px;
    width: 13px;
    display: inline-block;
    vertical-align: middle;
}

.PreviewOptionCover {
    padding: 2px;
    cursor: pointer;
    border: 2px solid rgba(0,0,0,0);
    width: 220px;
}

.PreviewOptionColor {
    width: 220px;
}

.PreviewOptionColor input {
    height: auto !important;
    text-align: center;
}

.PreviewOptionCoverSelected {
    border: 2px solid darkorange;
}

.PreviewOptionInput img {
    width: 100%;
    height: auto;
}

.ShowDetails {
	position: absolute;
	top: 0;
	right: 0;
	width: 35px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	font-size: 12pt;
	color: #aaa;
	box-shadow: 2px 2px 3px rgba(0,0,0,0.05);
	cursor: pointer;
	z-index: 3;
	transition: color 0.3s;
}

.ShowDetails:hover {
	color: #eee;
}

.PreviewTrackList {
    width: 90%;
    margin: 10px auto 0 auto;
}

.PreviewTrack {
    border-bottom: 1px solid #999;
    background-color: #eee;
    font-family: 'Lato', sans-serif;
    font-size: 10pt;
    height: 25px;
    line-height: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
    padding: 0 10px;
}

.PreviewTrack:hover {
    background-color: #ccc;
}

.PreviewTrack:active {
    background-color: darkorange;
}

.Listen {
    background-color: orange;
}

.NewsletterParts, .PresskitParts {
    padding: 20px;
    position: relative;
}

.NewsletterPart, .PresskitPart {
    border: 1px solid #aaa;
    border-radius: 5px;
    box-shadow: 2px 2px 3px rgba(0,0,0,0.05);
    margin-bottom: 3px;
    background-color: rgba(255,255,255,0.9);
}

.NewsletterPartHandle, .PresskitPartHandle {
    vertical-align: top;
	display: inline-block;
	font-size: 12pt;	
	width: 27px;
	text-align: center;
	color: #ddd;
	cursor: move;
	user-select: none;
    border-right: 1px dashed #ddd;
    height: 40px;
    line-height: 40px;
}

.NewsletterPartToggle, .PresskitPartToggle {
    display: inline-block;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding-top: 4px;
    padding-left: 10px;
}

.NewsletterPartToggle input, .PresskitPartToggle input {
    width: 20px;
    height: 20px;
    line-height: 40px;
    cursor: pointer;
}

.NewsletterPartName, .PresskitPartName {
    display: inline-block;
    padding-left: 5px;
    font-size: 12pt;
    font-family: 'Lato', sans-serif;
    color: #444;
    font-weight: bold;
}

.NewsletterPartNameInactive, .PresskitPartNameInactive {
    color: #ddd;
}

.NewsletterPartShowDetails, .PresskitPartShowDetails {
    position: absolute;
    right: 10px;
    top: 12px;
    cursor: pointer;
}

.NewsletterPartDetails, .PresskitPartDetails {
    border-top: 1px solid #ddd;
}

.NewsletterPartDetail, .PresskitPartDetail {
    margin: 5px 10px;
    padding: 5px;
    box-shadow: 2px 2px 3px rgba(0,0,0,0.05);
    height: 40px;
}

.NewsletterPartDetailName, .PresskitPartDetailName {
    line-height: 30px;
    padding-left: 5px;
    font-size: 10pt;
    font-family: 'Lato', sans-serif;
    color: #444;
    width: 50%;
}

.NewsletterPartValue, .PresskitPartValue {
    position: absolute;
    right: 10px;
    top: 7px;
}

.NewsletterPartValue select, .PresskitPartValue select {
    padding: 3px;
    width: 120px;
    margin: 0;
    border-radius: 2px;
    box-shadow: none;
    text-shadow: none;
    letter-spacing: normal;
    background-color: #eee;
    color: #333;
}

.NewsletterPartCustomText, .PresskitPartCustomText {
    margin: 5px 10px;
    padding: 5px;
    box-shadow: 2px 2px 3px rgba(0,0,0,0.05);
}

.NewsletterPartCustomText textarea,.PresskitPartCustomText textarea {
    width: 100%;
    height: 100px;
    padding: 5px;
    border-color: #ddd;
}

.NewsletterPartImageUpload input, .PresskitPartImageUpload input {
    display: none;
}

.NewsletterAddParts, .PresskitAddParts {
    padding: 20px;
}

.NewsletterAddPart, .PresskitAddPart {
    border: 1px solid #aaa;
    border-radius: 5px;
    box-shadow: 2px 2px 3px rgba(0,0,0,0.05);
    margin-bottom: 3px;
    height: 40px;
    line-height: 40px;
    background-color: rgba(255,255,255,0.9);
    transition: background-color 0.3s;
    cursor: pointer;
}

.NewsletterAddPart:hover, .PresskitAddPart:hover {
    background-color: rgba(230,230,230,0.9);
}

.NewsletterAddPart:active, .PresskitAddPart:active {
    background-color: orange;
}

.NewsletterAddPartIcon, .PresskitAddPartIcon {
    vertical-align: top;
	display: inline-block;
	font-size: 12pt;	
	width: 27px;
	text-align: center;
	color: #333;	
	user-select: none;
	border-right: 1px dashed #ddd;
}

.NewsletterAddPartName, .PresskitAddPartName {
    display: inline-block;
    padding-left: 15px;
    font-size: 10pt;
    font-family: 'Lato', sans-serif;
    color: #444;
    font-weight: bold;
}

.NewsletterPartDelete, .PresskitPartDelete {
    color: red;
    position: absolute;
    right: 50px;
    top: 12px;
    cursor: pointer;
}

.MinipageUploadImage input {
    display: none;
}

.MinipageImage {
	text-align: center;
	color: #fff;
    cursor: pointer;
    width: 220px;
    margin: 5px auto;
}

.MinipageImageChange {
    font-family: 'Lato', sans-serif;	
	line-height: 30px;
	text-align: center;		
	color: #333;
	font-size: 9pt;
	text-transform: uppercase;
	text-shadow: none;
	background-color: rgba(255,255,255,0.8);
    box-shadow: 0px 3px 2px 0px rgba(0,0,0,0.3);
    transition: all 0.3s;
}

.MinipageImageChange:hover {
	background-color: #ddd;
}

